<template>
  <div class="dp_flex player_container">
    <kt-iphonepopup
      v-if="isKtIphoneOpen"
      @close-ktiphone="onCloseKtIphone"
      @click-ktiphone="onClickKtIphone"
      :freePlayerImg="freePlayerImg"
    />
    <kt-eventpopup v-if="isKtEventOpen" @close-ktevent="onCloseKtEvent" />
    <skelton-loading v-if="$store.getters['player/getIsLoading'] || carChangeLoading" />
    <setting-curation
      v-if="isOpenCuration"
      @update-curation="onUpdateCuration"
      @update-automatic="onUpdateAutomaticRecommPreferMusic"
      :title="settingCurationTitle"
      :isshowequalizer="isShowEqualizer"
      :curation="$store.getters['player/getCurationInfo']"
    />
    <player-favorite
      v-if="isFavoriteModal"
      :curationid="$store.getters['player/getCreatorCurationId']"
      :spaceid="$cookies.get('spaceId')"
      @close-favoritemodal="onCloseFavoriteModal"
      @confirm-modal="onFavoriteConfirmModal"
    />
    <channel-loading
      v-if="$store.getters['player/getLoadingStatus']"
      :channelStatus="$store.getters['player/getChannelStatus']"
    />
    <full-loading v-if="$store.getters['player/getIsLoading'] || carChangeLoading" :curupdate="curUpdate" />
    <player-header
      :expireDate="expireDate"
      :isshowspacename="false"
      :isshowalarm="false"
      @open-myspace="onOpenMySpace"
      :tabletplayer="'tablet_header'"
    />
    <like-comp v-if="showLike" :active="showLike ? 'on' : ''" @closelike="onCloseLike" />
    <hate-comp v-if="showHate" :active="showHate ? 'on' : ''" @closehate="onCloseHate" />
    <report-comp
      v-if="isReport"
      :musicId="$store.state.channelMusicInfo.musicId"
      @closeModal="[(isReport = false), (isConfirmMusicReport = true)]"
      @closeModal2="isReport = false"
    />
    <comment-comp v-if="isComment" :musicInfo="$store.state.channelMusicInfo" @closeModal="isComment = false" />
    <confirm-musicreport v-if="isConfirmMusicReport" @closeModal="isConfirmMusicReport = false" />
    <alert-modal v-if="isAlertModal" :alertText="alertText" @closeModal="closeAlertModal()" />
    <inaccessible-alert v-if="alertStatus" @closeAlert="reSelectRecommend" />
    <particles-bg
      v-if="weatherInfo.weatherGb === 'snow'"
      ref="particlesbg"
      id="particles-js"
      type="custom"
      :config="config"
      :bg="true"
      :class="{
        back: $store.getters['player/getIsLoading'] || weatherInfo.weatherGb !== 'snow'
      }"
    />
    <star-comp v-if="isOpenAnim" />
    <broadcast-info
      v-if="showBrandMusic"
      @closeBrandMusic="showBrandMusic = false"
      @getList="setBrandMusicList"
      :brandMusic="brandMusic"
      :mysoundmusicList="mySoundList"
      :isbrand="myChannelInfo.brandId ? true : false"
    />
    <car-curation v-if="isCarCurationModal" :carPurposeCheck="carPurposeCheck" @closePopup="closePopup" />
    <canvas
      width="1280"
      height="720"
      v-show="weatherInfo.weatherGb === 'rain' && channelType === 'B'"
      id="raincanvas"
      :class="{
        dim: $store.getters['player/getTutorial'].step1 || $store.getters['player/getTutorial'].step2,
        back: $store.getters['player/getIsLoading']
      }"
    ></canvas>
    <!--################################ 날씨 구름 ################################-->
    <div v-if="channelType === 'B'" class="cloudy_group">
      <img
        v-if="
          weatherInfo.id === 'afternoon' ||
            weatherInfo.id === 'dinner' ||
            weatherInfo.id === 'mornig' ||
            weatherInfo.id === 'night'
        "
        class="aplayz_nomal_cloud normal_cloud2"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/morning_cloud2.png"
        alt="구름"
      />
      <img
        v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
        class="mornigCloud_cloud mornigCloud_cloud1"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_morning.svg"
        alt="아침구름"
      />
      <img
        v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
        class="mornigCloud_cloud mornigCloud_cloud2"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_morning.svg"
        alt="아침구름"
      />
      <img
        v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
        class="mornigCloud_cloud mornigCloud_cloud3"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_morning.svg"
        alt="아침구름"
      />
      <img
        v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
        class="mornigCloud_cloud mornigCloud_cloud4"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_morning.svg"
        alt="아침구름"
      />

      <img
        v-if="
          weatherInfo.id === 'afternoonRain' ||
            weatherInfo.id === 'afternoonCloud' ||
            weatherInfo.id === 'dinnerRain' ||
            weatherInfo.id === 'dinnerCloud' ||
            weatherInfo.id === 'nightRain' ||
            weatherInfo.id === 'nightCloud'
        "
        class="afternoonCloud_cloud afternoonCloud_cloud1"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_afternoon.svg"
        alt="오후구름"
      />
      <img
        v-if="
          weatherInfo.id === 'afternoonRain' ||
            weatherInfo.id === 'afternoonCloud' ||
            weatherInfo.id === 'dinnerRain' ||
            weatherInfo.id === 'dinnerCloud' ||
            weatherInfo.id === 'nightRain' ||
            weatherInfo.id === 'nightCloud'
        "
        class="afternoonCloud_cloud afternoonCloud_cloud2"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_afternoon.svg"
        alt="오후구름"
      />
      <img
        v-if="
          weatherInfo.id === 'afternoonRain' ||
            weatherInfo.id === 'afternoonCloud' ||
            weatherInfo.id === 'dinnerRain' ||
            weatherInfo.id === 'dinnerCloud' ||
            weatherInfo.id === 'nightRain' ||
            weatherInfo.id === 'nightCloud'
        "
        class="afternoonCloud_cloud afternoonCloud_cloud3"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_afternoon.svg"
        alt="오후구름"
      />
      <img
        v-if="
          weatherInfo.id === 'afternoonRain' ||
            weatherInfo.id === 'afternoonCloud' ||
            weatherInfo.id === 'dinnerRain' ||
            weatherInfo.id === 'dinnerCloud' ||
            weatherInfo.id === 'nightRain' ||
            weatherInfo.id === 'nightCloud'
        "
        class="afternoonCloud_cloud afternoonCloud_cloud4"
        :class="{ back: $store.getters['player/getIsLoading'] }"
        src="/media/img/cloud_afternoon.svg"
        alt="오후구름"
      />
    </div>

    <!--################################ Pc ################################-->
    <div class="aplayz_player_wrap pc">
      <player-curationinfo
        :toptext="isActiveChannel ? 'MY' : '실시간'"
        :channelname="spacename"
        :curationlist="$store.getters['player/getTagList']"
        :opentag="setOpen"
        :channeltype="channelType"
        :isbookmarkcheck="isBookmarkCheck"
        :issetting="isActiveChannel"
        :usertype="getUserType"
        @open-curation="openCuration"
        @click-bookmark="setManageBookMark($store.state.channelId)"
      />
      <ul class="aplayz_player_group">
        <li>
          <myplayer-left
            v-if="getUserType !== USER_TYPE.END_FREE && getUserType !== USER_TYPE.END_FREE_NO_SPACE"
            :musicinfo="!isPlayBrandMusic ? $store.getters.gettersChannelMusicInfo : brandMusicInfo"
            :isplaymusic="isPlayMusic"
            :isactivechannel="isActiveChannel"
            :spacepayat="$store.state.spacePayAt"
            :islike="isLike"
            :isunlike="isUnLike"
            :activetab="activeTab"
            :mychannelinfo="$store.getters['player/getMyChannelInfo']"
            :clickchannel="clickChannel"
            :usertype="getUserType"
            :setmanagebookmark="setManageBookMark"
            :isFreeMusicPlaying="isFreeMusicPlaying"
            :freePlayerImg="freePlayerImg"
            @click-like="onClickLike"
            @click-hate="onClickHate"
            @click-comment="onClickComment"
            @click-report="onClickReport"
            @playcontrol="onPlayBtn"
          />
        </li>
        <li class="player_right_wrap">
          <myplayer-right
            v-if="getUserType !== USER_TYPE.END_FREE && getUserType !== USER_TYPE.END_FREE_NO_SPACE"
            :popularchannellist="$store.getters['player/getPopularityChannelList']"
            :otherchannellist="designatedRecommendedChannelsList"
            :updatedate="$store.getters['player/getRecommDateHHMM']"
            :clickchannel="clickChannel"
            :activetab="activeTab"
            :isplay="isPlayMusic"
          />
        </li>
        <li
          class="nonspace_play"
          v-if="getUserType === USER_TYPE.END_FREE || getUserType === USER_TYPE.END_FREE_NO_SPACE"
        >
          <img class="nonspace_play_img" src="/media/img/player/spacedummyimg.png" alt="dummy" />
          <endfree-space
            @currentspace-subscribe="linkPayment"
            @newspace-subscribe="goSpaceInsert"
            :usertype="getUserType"
          />
        </li>
        <li
          id="brand_icon"
          class="aplayz_brand_icon"
          v-if="
            myChannelInfo &&
              myChannelInfo.spaceType === 'Store' &&
              $store.state.spacePayAt &&
              $store.state.spacePayAt !== 'T' &&
              $store.state.spacePayAt !== 'N'
          "
          @click="openBrandMusic"
        >
          <!-- 2024.12.19 백엔드 이슈로 인하여 브랜드있는 경우 브랜드 아이콘만 뜨도록 변경-->
          <img
            :class="myChannelInfo.brandId ? 'brand_icon' : 'mysound_icon'"
            :src="
              myChannelInfo.brandId
                ? '/media/img/webCastPlayer/icon_brand_music.svg'
                : '/media/img/webCastPlayer/icon_speaker.png'
            "
            alt="brand music"
          />
          <!--<img class="brand_icon" src="/media/img/webCastPlayer/icon_brand_music.svg" alt="brand music" />-->
        </li>
      </ul>
    </div>
    <!--################################ Mobile ################################-->
    <div class="aplayz_player_wrap mo">
      <nonuser-space-m
        v-if="
          isSubscribeSpaceModal && (getUserType === USER_TYPE.END_FREE || getUserType === USER_TYPE.END_FREE_NO_SPACE)
        "
        :usertype="getUserType"
        @close-modal="isSubscribeSpaceModal = false"
        @new-spacesubscribe="goSpaceInsert"
        @currentspace-subscribe="linkPayment"
      />
      <install-induce v-if="openInstallInduce" :dimcss="'blur'" @today-stay="closeInstallInduce" />

      <img
        v-if="getMusicInfo && !isPlayBrandMusic"
        class="song_album_mo"
        :src="getMusicInfo.albumImg.includes('emptyImg') ? getPlaceHolder(getMusicInfo.musicId) : getMusicInfo.albumImg"
        :alt="getMusicInfo.musicNm"
      />
      <img
        v-else-if="isPlayBrandMusic && brandMusicInfo"
        class="song_album_mo"
        :src="`${brandMusicInfo.albumImg}`"
        :alt="getMusicInfo.musicNm"
      />

      <div class="mo_playlist_control">
        <img
          @click="onClickLike"
          class="like_m"
          :src="isLike ? '/media/img/like_icon_active.svg' : '/media/img/like_icon_off.svg'"
          alt="좋아요"
        />
        <p class="mo_music_info">
          <span
            v-if="getMusicInfo && !isPlayBrandMusic"
            class="mo_song_title"
            v-rolling="{ str: getMusicInfo.musicNm, speed: 10 }"
          ></span>
          <span
            v-else-if="isPlayBrandMusic && brandMusicInfo"
            class="mo_song_title"
            v-rolling="{ str: brandMusicInfo.musicNm, speed: 10 }"
          ></span>
          <span
            v-if="getMusicInfo && !isPlayBrandMusic"
            class="mo_song_artist"
            v-rolling="{ str: getMusicInfo.artist, speed: 10 }"
          ></span>
          <span
            v-else-if="isPlayBrandMusic && brandMusicInfo"
            class="mo_song_artist"
            v-rolling="{ str: brandMusicInfo.artist, speed: 10 }"
          ></span>
        </p>
        <img
          src="/media/img/webCastPlayer/more_horiz.svg"
          alt="more_m"
          @click="onClickIsMobileMusicInfo"
          class="more_m"
        />
      </div>
      <div class="mo_playlist_control2">
        <div class="mo_control2_wrap" @click="onPlayBtn">
          <img
            class="mo_control2_pause"
            :src="isPlayMusic ? '/media/img/webCastPlayer/icon_pause.svg' : '/media/img/webCastPlayer/icon_play.svg'"
            alt="pause"
          />
        </div>
      </div>

      <div class="mo_bottom">
        <li
          class="mo_aplayz_brand_icon"
          v-if="
            myChannelInfo &&
              myChannelInfo.spaceType === 'Store' &&
              myChannelInfo.brandId &&
              $store.state.spacePayAt !== 'T'
          "
          @click="openBrandMusicM"
        >
          <img src="/media/img/webCastPlayer/icon_brand_music.svg" />
        </li>
        <img
          id="setting_icon"
          @click="openisMobileCuration"
          src="/media/img/webCastPlayer/curation_setting_on.svg"
          alt=""
          width="28"
          height="28"
        />
        <p class="mo_channel_info">
          <span class="mo_channel_border"> 채널 </span>
          <span class="mo_channel_name">{{ spacename }}</span>
          <span class="mo_player_eq"></span>
        </p>
        <img
          src="/media/img/webCastPlayer/icon_m_channel.svg"
          @click="openRecommlistMo"
          alt=""
          width="28"
          height="28"
        />
      </div>
      <myspace-mobile
        @close-myspace="onCloseMySpace"
        @newcreate-channel="onNewCreateChannel"
        :isactive="isMobileMySpace"
      />
      <musicinfo-mobile
        :musicinfo="$store.getters.gettersChannelMusicInfo"
        :isactive="isMobileMusicInfo"
        @close-musicinfo="isMobileMusicInfo = ''"
        @open-comment="onClickComment"
        @click-dislike="onClickHate"
        @open-report="isReport = true"
      />
      <recommendchannel-mobile
        :isactive="isMobileRecommend"
        @close-recom="isMobileRecommend = ''"
        :playerinfofn="setPlayerInfo"
      />
      <settingcuration-mobile
        v-if="isMobileCuration"
        :curation="$store.getters['player/getCurationInfo']"
        @confirm-modal="onCofirmSettingCuration"
        @close-curation="isMobileCuration = false"
        @confirm-modalcuraion="onSettingCurationMobile"
      />
      <player-favoritemobile
        v-if="isMobileFavoriteMusic"
        @close-modal="onCloseFavoriteMobileModal"
        @complete-favorite="onFavoriteConfirmModal"
        :curationid="$store.getters['player/getCreatorCurationId']"
        :spaceid="$cookies.get('spaceId')"
      />
      <mobrand-music
        v-if="popupStatus"
        :brandMusic="brandMusic"
        @closePopup="popupStatus = false"
        @getBrandList="setBrandMusicList"
      />
    </div>
  </div>
</template>
<script>
import rolling from '@/utils/directive/rolling';
import vClickOutside from 'v-click-outside';
import {
  getAutoPlay,
  mySpaceInfo,
  getSpaceCurationInfo,
  selectShowRoomEnterCheck,
  updateShowRoomEnterCheckN,
  subscribeSpaceList,
  getMySpaceList,
  selectCurationInfoAll,
  updateConfirmCuration,
  automaticRecommOfPreferMusic,
  updatePreferMusicList
} from '@/service/api/profileApi';
import { rainAnimation } from '@/assets/js/rainAnimation';
import { ParticlesBg } from 'particles-bg-vue';
import { leaveChannel } from '@/assets/js/webcast';
import { checkDupleUse } from '@/service/api/spaceSessionApi';
import {
  getUserCase,
  insertUserMusicLikeOrDislike,
  getUserMusicLikeOrDislike,
  selectUserBookmarkChannelList,
  userFixedChannel
} from '@/service/api/userApi';
import {
  getPlayerInfo,
  getChannelMusicInfo,
  checkLike,
  setLikeMusic,
  getChannelInfo,
  selectPopularityChannels,
  selectDesignatedRecommendedChannels,
  selectSimilarChannels,
  selectBookmarkChannels,
  getBrandSpaceMusicList,
  getCurationInfo,
  selectAllChannel,
  insertMusicPlayHistoryApi
} from '@/service/api/playerApi';
import { cloneDeep } from 'lodash';
import Hls from 'hls.js';
import { USER_TYPE, getterUserType } from '@/assets/js/usertype';
import { retryAutomaticRecomm, setBackGroundImg, isNotPc } from '@/utils/Utils';
import { getCurrentWeather } from '@/service/api/weatherApi';
import { selectExternalEvent, updateEventClickCount } from '@/service/api/mobileApi';

export default {
  name: 'Player',
  directives: {
    clickOutside: vClickOutside.directive,
    rolling
  },
  components: {
    ParticlesBg,
    'player-header': () => import(`@/layout/header/PlayerHeader.vue`),
    'channel-loading': () => import(`@/components/modal/PlayerWS/common/Loading.vue`),
    'full-loading': () => import(`@/components/modal/PlayerWS/common/FullLoading.vue`),
    'like-comp': () => import(`@/components/modal/Player/Like.vue`),
    'hate-comp': () => import(`@/components/modal/Player/Hate.vue`),
    'comment-comp': () => import(`@/components/modal/Player/Comment.vue`),
    'report-comp': () => import(`@/components/modal/Player/Report.vue`),
    'confirm-musicreport': () => import(`@/components/modal/Player/ConfirmMusicReport.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`),
    'inaccessible-alert': () => import(`@/components/modal/common/InaccessibleAlert.vue`),
    'star-comp': () => import(`@/components/player/StarComp.vue`),
    'setting-curation': () => import(`@/components/player/SettingCuration.vue`),
    'broadcast-info': () => import(`@/components/modal/Player/BroadCastInfo.vue`),
    'car-curation': () => import(`@/components/modal/Player/CarCuration.vue`),
    'myspace-mobile': () => import(`@/components/player/MySpaceMobile.vue`),
    'musicinfo-mobile': () => import(`@/components/player/MusicInfoMobile.vue`),
    'recommendchannel-mobile': () => import(`@/components/player/RecommendChannelMobile.vue`),
    'settingcuration-mobile': () => import(`@/components/player/SettingCurationM.vue`),
    'mobrand-music': () => import(`@/components/modal/PlayerWS/MoWsBrandContent.vue`),
    'player-curationinfo': () => import(`@/components/player/PlayerCurationInfo.vue`),
    'myplayer-left': () => import(`@/components/player/MyPlayerLeft.vue`),
    'myplayer-right': () => import(`@/components/player/MyPlayerRight.vue`),
    'install-induce': () => import(`@/components/modal/experience/InstallInduce.vue`),
    'endfree-space': () => import(`@/components/player/EndFreeSpace.vue`),
    'nonuser-space-m': () => import(`@/components/player/NonUserSpaceM.vue`),
    'skelton-loading': () => import(`@/components/player/SkeletonLoading.vue`),
    'player-favorite': () => import(`@/components/player/PlayerFavorite.vue`),
    'player-favoritemobile': () => import(`@/components/player/PlayerFavoriteMobile.vue`),
    'kt-eventpopup': () => import(`@/components/collaboration/KtEventPopUp.vue`),
    'kt-iphonepopup': () => import(`@/components/eventView/ktiphone/KtIphonePopUp.vue`)
  },
  data() {
    return {
      isKtIphoneOpen: false,
      isKtEventOpen: false,
      popupStatus: false,
      storeHlsWatch: () => {},
      carPurposeCheck: null,
      showBrandMusic: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      carChangeLoading: false,
      lon: null,
      lat: null,
      showLike: false,
      showHate: false,
      isComment: false,
      isReport: false,
      isConfirmMusicReport: false,
      channelInfoInterval: -1,
      tagsList: [],
      infoInterval: -1,
      curUpdate: '',
      config: {
        num: 1,
        rps: -1,
        radius: [15, 15],
        life: [10, 10],
        v: 0.1,
        tha: [150, 200],
        alpha: [0.3, 1],
        scale: [0.1, 0.4],
        position: 'all',
        cross: 'dead',
        random: 10,
        color: '#ffffff',
        g: 0.5
      },
      counter: this.$store.state.counter,
      cheerSrc: null,
      isMobileMySpace: '',
      isMobileMusicInfo: '',
      isMobileRecommend: '',
      isMobileCuration: false,
      isMobileFavoriteMusic: false,
      mapBookMark: [],
      similarInterval: -1,
      timeOutId: -1,
      recommInterval: -1,
      recommTimeOut: -1,
      spaceShowroomCheck: '',
      showroomInterval: -1,
      isShowRoomChecking: false,
      brandMusicInfoList: [],
      getUserType: -1,
      openInstallInduce: false,
      USER_TYPE: USER_TYPE,
      isSubscribeSpaceModal: false,
      isFavoriteModal: false,
      returnCurationInfo: null,
      placeHolder: '',
      spaceId: this.$cookies.get('spaceId'),
      settingCurationTitle: '',
      isShowEqualizer: false,
      routeParams: null,
      brandMusicHardCodingList: [],
      isFreeMusicPlaying: false,
      freePlayerImg: '',
      isFreeCouponUser: false,
      eventInfo: {}
    };
  },
  async created() {
    await this.getSelectExternalEventApi(); // 이벤트 설정 q1
    // 브랜드음원 하드코딩
    this.setBrandMusicHardCoding();
    if (!isNotPc()) {
      this.checkKtIphoneOpen();
      this.checkKtEventOpen();
    }

    let spaceSt;
    let spaceTp = '';
    let carMusicUrl = '';
    if (Object.keys(this.$route.params).length > 0) {
      this.routeParams = this.$route.params;
    }
    this.setCurationList();
    if (Object.keys(this.$route.params).length > 0) {
      const { setIsLoading } = this.$route.params;
      this.$store.commit('player/setIsLoading', setIsLoading);
    }
    this.isOpenInstallInduce();
    if (this.$store.getters['player/getActiveTab'] === '') {
      this.$store.commit('player/setActiveTab', 'my');
    }
    if (Object.keys(this.$route.params).length > 0) {
      const { curUpdate, spaceState, spaceType, musicUrl } = this.$route.params;
      spaceSt = spaceState;
      spaceTp = spaceType;
      carMusicUrl = musicUrl;
      this.curUpdate = curUpdate;
    } else {
      this.curUpdate = '';
    }
    if (spaceSt === 'changeSpace' && spaceTp === 'Car') {
      const spaceId = this.$cookies.get('spaceId');
      this.$store.commit('player/setIsLoading', true);
      getCurationInfo(spaceId).then(res => {
        if (res.data.resultCd === '0000') {
          this.carPurposeCheck = res.data.result.carPurposeCheck; // 차량 이용목적
          if (this.carPurposeCheck === 'Y') {
            this.$store.commit('setCarCurationModal', true);
          } else {
            this.init();
          }
        }
      });
    } else if (spaceSt === 'callTTS' && spaceTp === 'Car') {
      this.cheerSrc = carMusicUrl;
      this.$CarPlayer.setCarVideo();
      this.$CarPlayer.setVideoSrc(this.cheerSrc);
      this.$CarPlayer.setMute(false);
      this.$CarPlayer.onCarPurposePlay();
      this.$CarPlayer.registerOnceCarVideoEvent('ended', this.carPurPoseEnded);
    } else {
      this.init();
    }
    this.$store.dispatch('player/settingMyChannelInfo');
    this.similarChannelInterval();
  },

  mounted() {
    this.storeHlsWatch = this.$store.watch(
      () => this.$store.getters.getHls,
      () => {
        this.$VideoPlayer.registerHlsErrorHandle(this.hlsErrorHandler);
      }
    );
    if (this.$MediaSession.init()) {
      this.$MediaSession.addPause(this.mediaSessionPause);
      this.$MediaSession.addPlay(this.mediaSessionPlay);
      this.$MediaSession.addPrevioustrack(this.channelPreBtn);
      this.$MediaSession.addNexttrack(this.channelNextBtn);
    }

    this.$nextTick(() => {
      if (this.routeParams) {
        if (Object.keys(this.routeParams).includes('openCuration')) {
          if (this.routeParams.osType === 'mobile') {
            this.isMobileCuration = true;
          } else {
            this.$store.commit('player/setIsOpenCurationSetting', this.routeParams.openCuration);
          }
        }
      }
    });

    this.setIntervalGroup();
  },

  methods: {
    /**
     * @description : "애슐리 퀸즈 공덕점"의 브랜드음원중에 "런치마감 디너교체 1(3973)","런치마감 디너교체 2(3974)"는 월~금만 사용할 수 있도록 하드코딩처리
     */
    setBrandMusicHardCoding() {
      const domain = location.host;
      // prod
      if (domain === 'www.aplayz.co.kr') {
        this.brandMusicHardCodingList.push({
          spaceId: 'space_010928',
          brandSpaceMusicId: ['3973', '3974'],
          week: '1,2,3,4,5'
        });
      } else {
        this.brandMusicHardCodingList.push({
          spaceId: 'space_003906',
          brandSpaceMusicId: ['6330', '6333'],
          week: '1,2,3,4,5'
        });
      }
    },
    setCurationList() {
      try {
        selectCurationInfoAll(this.spaceId)
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              //
              this.$store.commit('player/setTimeCurationList', result);
            } else {
              //
              this.$store.commit('player/setTimeCurationList', []);
            }
          })
          .catch(() => {});
      } catch (error) {
        //
        this.$store.commit('player/setTimeCurationList', []);
      }
    },
    onCloseFavoriteMobileModal() {
      this.isMobileFavoriteMusic = false;
    },
    onCofirmSettingCuration() {
      this.isMobileFavoriteMusic = true;
    },
    onCloseKtEvent(val) {
      this.isKtEventOpen = false;

      if (val) {
        const curFormatDate = new Date(this.$common.curFormatDate());
        localStorage.setItem('openKtEventTime', curFormatDate.getTime());
      }
    },
    checkKtEventOpen() {
      const startOpenEvent = new Date('2024-09-05 00:00');
      const endOpenEvent = new Date('2024-09-05 23:59');
      const curDate = new Date();

      if (curDate.getTime() >= startOpenEvent.getTime() && curDate.getTime() <= endOpenEvent.getTime()) {
        const openKtEventTime = localStorage.getItem('openKtEventTime');

        if (!openKtEventTime) {
          this.isKtEventOpen = true;
        } else {
          const curFormatDate = this.$common.curFormatDate();
          const expiredFormatDate = this.$common.setFormatDate(openKtEventTime);
          if (curFormatDate !== expiredFormatDate) {
            this.isKtEventOpen = true;
          } else {
            this.isKtEventOpen = false;
          }
        }
      } else {
        this.isKtEventOpen = false;
      }
    },
    async onClickKtIphone() {
      // q1
      this.isKtIphoneOpen = false;
      await this.updateEventClickCountApi();
      const openUrl = this.eventInfo.eventUrl;
      window.open(openUrl, '_blank');
    },
    onCloseKtIphone(val) {
      this.isKtIphoneOpen = false;
      if (val) {
        const curFormatDate = new Date(this.$common.curFormatDate());
        localStorage.setItem('openKtIphoneTime', curFormatDate.getTime());
      }
    },
    checkKtIphoneOpen() {
      // const startOpenEvent = new Date('2025-01-21 00:00');
      // const endOpenEvent = new Date('2025-02-01 23:59');
      const startEvent = new Date(this.eventInfo.eventStartTime);
      const endEvent = new Date(this.eventInfo.eventEndTime);

      const curDate = new Date(); //
      if (curDate.getTime() >= startEvent.getTime() && curDate.getTime() <= endEvent.getTime()) {
        const openKtIphoneTime = localStorage.getItem('openKtIphoneTime');

        if (!openKtIphoneTime) {
          this.isKtIphoneOpen = true;
        } else {
          const curFormatDate = this.$common.curFormatDate();
          const expiredFormatDate = this.$common.setFormatDate(openKtIphoneTime);
          if (curFormatDate !== expiredFormatDate) {
            this.isKtIphoneOpen = true;
          } else {
            this.isKtIphoneOpen = false;
          }
        }
      } else {
        this.isKtIphoneOpen = false;
      }
    },

    getPlaceHolder(musicId) {
      if (this.placeHolder === '' || this.getMusicInfo.musicId !== musicId) {
        this.placeHolder = this.getMusicInfo.albumImg;
      }
      return this.placeHolder;
    },
    onSettingCurationMobile({ curationInfo }) {
      this.returnCurationInfo = curationInfo;
      this.onFavoriteConfirmModal();
    },
    async onFavoriteConfirmModal(checkAutoRecomm = false, selectPreferMusic = [], isFromFavorite = false) {
      this.isFavoriteModal = false;
      this.$store.commit('player/setIsOpenCurationSetting', false);
      this.isMobileFavoriteMusic = false;
      this.isMobileCuration = false;
      const curationInfo = this.returnCurationInfo;

      const { spaceType } = this.$store.getters['player/getSpaceInfo'];
      const spaceId = this.$cookies.get('spaceId');
      this.$store.commit('player/setIsOpenCurationSetting', false);
      this.$store.commit('player/setIsLoading', true);
      this.$VideoPlayer.onPause();

      // checkAutoRecomm
      if (isFromFavorite) {
        if (checkAutoRecomm) {
          try {
            const { data } = await automaticRecommOfPreferMusic(this.$store.getters['player/getCreatorCurationId']);
            const { resultCd } = data;
            if (resultCd !== '0000') return false;
          } catch (error) {
            if (error.response) {
              const { url } = error.response.config;
              if (url === '/api/profile/automaticRecommOfPreferMusic') {
                console.log(error.response);
                retryAutomaticRecomm(
                  0,
                  this.$store.getters['player/getCreatorCurationId'],
                  () => {
                    this.$store.commit('player/setIsLoading', false);
                  },
                  () => {
                    this.$store.commit('player/setIsLoading', false);
                  }
                );
              }
            }
          }
        } else {
          try {
            const { data } = await updatePreferMusicList(
              spaceId,
              selectPreferMusic,
              this.$store.getters['player/getCreatorCurationId']
            );
            const { resultCd } = data;
            if (resultCd !== '0000') return false;
          } catch (error) {
            this.$store.commit('player/setIsLoading', false);
          }
        }
      }

      const { data: noticeTypeData } = await updateConfirmCuration(
        spaceId,
        this.$store.getters['player/getCreatorCurationId']
      );
      const { resultCd: noticeTypeResultCd } = noticeTypeData;
      if (noticeTypeResultCd === '0000') {
        await leaveChannel();
        this.$store.commit('setChannelId', '');
        this.$store.commit('setChannelUrl', null);
        if (spaceType === 'Car') {
          const carPurPose = parseInt(curationInfo.carPurpose);
          const find = this.$store.getters['curation/getCarPurpose'].find(item => item.value === carPurPose);
          this.cheerSrc = find.musicUrl;
          this.$CarPlayer.setCarVideo();
          this.$CarPlayer.setVideoSrc(this.cheerSrc);
          this.$CarPlayer.setMute(false);
          this.$CarPlayer.onCarPurposePlay();
          this.$CarPlayer.registerOnceCarVideoEvent('ended', this.carPurPoseEnded);
        } else {
          await this.$store.dispatch('player/createChannel', { retry: false, playerinfofn: this.setPlayerInfo });
        }
        this.setBg();
      } else {
        this.$store.commit('player/setIsLoading', false);
      }
    },
    onCloseFavoriteModal() {
      this.isFavoriteModal = false;
    },
    async onUpdateCuration({ curationInfo }) {
      this.returnCurationInfo = curationInfo;
      this.isFavoriteModal = true;
    },
    async onUpdateAutomaticRecommPreferMusic({ curationInfo }) {
      this.returnCurationInfo = curationInfo;
      this.onFavoriteConfirmModal();
    },
    intervalRecommJob() {
      this.$crontab.deleteJob('recommchannel');
      const hour = [];
      for (let i = 0; i < 24; i++) {
        hour.push(i);
      }
      this.$crontab.addJob({
        name: 'recommchannel',
        interval: { seconds: '0', minutes: '0', hours: hour.join(',') },
        job: () => {
          this.allFixedChannels();
        }
      });
    },
    isOpenInstallInduce() {
      const userAgent = navigator.userAgent;
      if (!userAgent.includes('APLAYZ PoS')) {
        const getInstallInducePopUp = this.$cookies.get('inducdenddate');
        if (getInstallInducePopUp) {
          const curDate = new Date();
          const curYyyy = curDate.getFullYear();
          const curMm = curDate.getMonth() + 1 > 9 ? `${curDate.getMonth() + 1}` : `0${curDate.getMonth() + 1}`;
          const curDd = curDate.getDate() > 9 ? curDate.getDate() : `0${curDate.getDate()}`;

          const splitInstallInducePopup = getInstallInducePopUp.split('-');
          const yyyy = splitInstallInducePopup[0];
          const mm = splitInstallInducePopup[1];
          const dd = splitInstallInducePopup[2];

          const prevDate = new Date(`${curYyyy}-${curMm}-${curDd}`);
          const getDate = new Date(`${yyyy}-${mm}-${dd}`);

          if (prevDate.getTime() > getDate.getTime()) {
            this.openInstallInduce = true;
          }
        } else {
          this.openInstallInduce = true;
        }

        if (!this.openInstallInduce) {
          this.checkKtIphoneOpen();
          this.checkKtEventOpen();
        }
      }
    },
    closeInstallInduce() {
      this.openInstallInduce = false;
      const curDate = new Date();
      const curYyyy = curDate.getFullYear();
      const curMm = curDate.getMonth() + 1 > 9 ? `${curDate.getMonth() + 1}` : `0${curDate.getMonth() + 1}`;
      const curDd = curDate.getDate() > 9 ? curDate.getDate() : `0${curDate.getDate()}`;
      this.$cookies.set('inducdenddate', `${curYyyy}-${curMm}-${curDd}`);
      this.checkKtIphoneOpen();
      this.checkKtEventOpen();
    },
    async setUserType(trialYn = 'N', spaceYn = 'N') {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      try {
        const { data } = await subscribeSpaceList(userId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.getUserType = getterUserType(trialYn, spaceYn, result);
        }
      } catch (error) {
        const { data } = await getMySpaceList(userId, 10, spaceId);
        const { result } = data;
        const { spaceInfoListInfo } = result;
        const filter = spaceInfoListInfo.filter(item => item.spacePayAt === 'Y');
        this.getUserType = getterUserType(trialYn, spaceYn, filter);
      } finally {
        if (this.getUserType === USER_TYPE.END_FREE || this.getUserType === USER_TYPE.END_FREE_NO_SPACE) {
          this.isSubscribeSpaceModal = true;
        }
      }
    },
    warningAlert() {
      this.$store.commit('player/setIsAlertModal', true);
      this.$store.commit('player/setAlertText', '구독중인 스페이스에서만 이용이 가능합니다.');
      setTimeout(() => {
        this.$router.push({ name: 'MySpace' });
      }, 5000);
    },
    async getUseSpace() {
      try {
        const userId = this.$cookies.get('userId');
        const { data } = await getUserCase(userId);
        const { result, resultCd } = data;
        if (resultCd === '0000') {
          await this.setUserType(result.trialYn, result.spaceYn);

          return {
            isUseSpace: true,
            trialYn: result.trialYn,
            spaceYn: result.spaceYn
          };
        } else {
          return {
            isUseSpace: false,
            trialYn: 'N',
            spaceYn: 'N'
          };
        }
      } catch (error) {
        return {
          isUseSpace: false,
          trialYn: 'N',
          spaceYn: 'N'
        };
      }
    },
    openRecommlistMo() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        this.isMobileRecommend = 'active';
        this.$gatag.event('click_recomlist_mo', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId
        });
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    openisMobileCuration() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        this.isMobileCuration = true;
        this.$gatag.event('click_curation_mo', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId
        });
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    async openBrandMusicM() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        await this.setBrandMusicList();
        this.popupStatus = true;
        this.$gatag.event('click_brand_mo', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId
        });
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    async openBrandMusic() {
      await this.setBrandMusicList();
      this.showBrandMusic = true;
      this.$gatag.event('click_brand', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    setChannelTrackList() {
      const mapSimilar = this.$store.getters['player/getSimilarChannelList'].map(item => {
        return {
          ...item,
          activeTab: 'similar'
        };
      });
      this.mapBookMark = this.$store.getters['player/getBookmarkChannels'].map(item => {
        return {
          ...item,
          activeTab: 'bookmark'
        };
      });
      // 인기
      const mapPopular = this.$store.getters['player/getPopularityChannelList'].map(item => {
        return {
          ...item,
          activeTab: 'popular'
        };
      });
      let mapDesign = this.$store.getters['player/getDesignatedRecommendedChannels'].map(item => {
        return {
          ...item,
          activeTab: 'design'
        };
      });
      mapDesign = mapDesign.filter(item => {
        if (!this.mapBookMark.find(bookMarkItem => bookMarkItem.channelId === item.channelId)) {
          return true;
        }

        return false;
      });

      return {
        mapSimilar,
        mapPopular,
        mapDesign,
        mapBookMark: this.mapBookMark
      };
    },
    sortChannelTrackList(activeTab) {
      let nextMoveArray = [];
      const { mapSimilar, mapPopular, mapDesign, mapBookMark } = this.setChannelTrackList();
      if (activeTab === 'my') {
        // 내채널,유사채널,즐겨찾기 채널, 인기급상승 채널, 이런채널은 어때요?

        nextMoveArray = [
          {
            ...this.$store.getters['player/getMyChannelInfo'],
            activeTab: 'my'
          },
          ...mapSimilar,
          ...mapBookMark,
          ...mapPopular,
          ...mapDesign
        ];
      } else if (activeTab === 'similar') {
        // 1) 유사채널, 2) 내채널, 3), 즐겨찾기한 채널, 4) ‘인기급상승’ 채널, 5) ‘이런 채널은 어때요?’ 채널
        nextMoveArray = [
          ...mapSimilar,
          {
            ...this.$store.getters['player/getMyChannelInfo'],
            activeTab: 'my'
          },

          ...mapBookMark,
          ...mapPopular,
          ...mapDesign
        ];
      } else if (activeTab === 'design') {
        // 1)‘이런 채널은 어때요?’ 채널, 2) ‘인기급상승’ 채널, 3) 내채널, 4) 유사채널, 5) 즐겨찾기 채널
        nextMoveArray = [
          ...mapDesign,

          ...mapPopular,
          {
            ...this.$store.getters['player/getMyChannelInfo'],
            activeTab: 'my'
          },
          ...mapSimilar,
          ...mapBookMark
        ];
      } else if (activeTab === 'popular') {
        // 1) ‘인기급상승’ 채널, 2) ‘이런 채널은 어때요?’ 채널, 3) 내채널, 4) 유사채널, 5) 즐겨찾기 채널
        if (this.$store.getters['player/getMyChannelInfo']) {
          nextMoveArray = [
            ...mapPopular,
            ...mapDesign,
            {
              ...this.$store.getters['player/getMyChannelInfo'],
              activeTab: 'my'
            },
            ...mapSimilar,
            ...mapBookMark
          ];
        } else {
          nextMoveArray = [...mapPopular, ...mapDesign, ...mapSimilar, ...mapBookMark];
        }
      } else if (activeTab === 'bookmark') {
        nextMoveArray = [
          {
            ...this.$store.getters['player/getMyChannelInfo'],
            activeTab: 'my'
          },
          ...mapBookMark
        ];
      }

      return nextMoveArray;
    },
    channelPreBtn() {
      const activeChannel = this.$store.getters['player/getStartChannelInfo'];
      //
      const reActiveTab = this.$store.getters['player/getActiveTab'];
      let nextMoveArray = [];

      if ((Object.keys(activeChannel).length > 0 || reActiveTab) && !this.$store.getters['player/getLoadingStatus']) {
        let activeTab = '';
        if (Object.keys(activeChannel).length > 0) {
          const { activeTab: acTab } = activeChannel;
          activeTab = acTab;
        } else {
          activeTab = reActiveTab;
        }
        nextMoveArray = this.sortChannelTrackList(activeTab);

        const findActiveChannelIndex = nextMoveArray.findIndex(
          item =>
            `${item.activeTab}_${item.channelId}` ===
            `${this.$store.getters['player/getActiveTab']}_${this.$store.state.channelId}`
        );

        if (activeChannel.activeTab === 'bookmark' && nextMoveArray.length === 1) {
          const startItem = nextMoveArray[findActiveChannelIndex];

          const realActivTab = this.$store.getters['player/getActiveTab'];

          this.$store.commit('player/setStartChannelInfo', {
            ...startItem
          });
          nextMoveArray = this.sortChannelTrackList(realActivTab);
        }
        if (findActiveChannelIndex > -1) {
          const prev = findActiveChannelIndex - 1 > -1 ? findActiveChannelIndex - 1 : nextMoveArray.length - 1;

          nextMoveArray[prev].playerinfofn = this.setPlayerInfo;

          this.$store.commit('player/setActiveTab', nextMoveArray[prev].activeTab);
          if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
            nextMoveArray[prev].playerinfofn = this.setNonSpaceChangeChannel;
            this.$store.dispatch('player/setNonSpaceJoinChannel', nextMoveArray[prev]);
          } else {
            this.$store.dispatch('player/joinChannel', nextMoveArray[prev]);
          }

          this.$gatag.event('click_pre_channel', {
            space_id: nextMoveArray[prev].spaceId,
            space_nm: nextMoveArray[prev].spaceNm,
            channel_id: nextMoveArray[prev].channelId
          });
        }
      }
    },
    channelNextBtn() {
      const activeChannel = this.$store.getters['player/getStartChannelInfo'];
      //
      let nextMoveArray = [];
      const reActiveTab = this.$store.getters['player/getActiveTab'];
      if ((Object.keys(activeChannel).length > 0 || reActiveTab) && !this.$store.getters['player/getLoadingStatus']) {
        let activeTab = '';
        if (Object.keys(activeChannel).length > 0) {
          const { activeTab: acTab } = activeChannel;
          activeTab = acTab;
        } else {
          activeTab = reActiveTab;
        }

        nextMoveArray = this.sortChannelTrackList(activeTab);
        const findActiveChannelIndex = nextMoveArray.findIndex(
          item =>
            `${item.activeTab}_${item.channelId}` ===
            `${this.$store.getters['player/getActiveTab']}_${this.$store.state.channelId}`
        );
        if (activeChannel.activeTab === 'bookmark' && nextMoveArray.length === 1) {
          const startItem = nextMoveArray[findActiveChannelIndex];
          const realActivTab = this.$store.getters['player/getActiveTab'];
          this.$store.commit('player/setStartChannelInfo', {
            ...startItem
          });
          nextMoveArray = this.sortChannelTrackList(realActivTab);
        }
        if (findActiveChannelIndex > -1) {
          const next = findActiveChannelIndex + 1 > nextMoveArray.length - 1 ? 0 : findActiveChannelIndex + 1;

          nextMoveArray[next].playerinfofn = this.setPlayerInfo;

          this.$store.commit('player/setActiveTab', nextMoveArray[next].activeTab);
          if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
            nextMoveArray[next].playerinfofn = this.setNonSpaceChangeChannel;
            this.$store.dispatch('player/setNonSpaceJoinChannel', nextMoveArray[next]);
          } else {
            this.$store.dispatch('player/joinChannel', nextMoveArray[next]);
          }

          this.$gatag.event('click_next_channel', {
            space_id: nextMoveArray[next].spaceId,
            space_nm: nextMoveArray[next].spaceNm,
            channel_id: nextMoveArray[next].channelId
          });
        }
      }
    },
    /**
     * @description 미디어세션 중지
     * @author CHOI DAE GEON
     */
    mediaSessionPause() {
      this.$VideoPlayer.onPause();
    },
    /**
     * @description 미디어세션 재생
     * @author CHOI DAE GEON
     */
    mediaSessionPlay() {
      this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
      this.playQurationMusic();
    },
    async onNewCreateChannel(item) {
      if (item) {
        this.$cookies.set('spaceId', item.spaceId);
        this.$store.commit('player/setIsLoading', true);
        this.$VideoPlayer.onPause();
        this.$store.commit('setChannelId', '');
        await this.$store.dispatch('player/createChannel', { retry: true });
        this.isMobileMySpace = '';
        this.init();
      }
    },
    onCloseMySpace() {
      this.isMobileMySpace = '';
    },
    onOpenMySpace() {
      this.isMobileMySpace = 'active';
      this.$gatag.event('click_spaceList_mo', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    getAutoPlayInfo() {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId) {
        getAutoPlay(spaceId).then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            const autoPlayInfo = {};
            // autoplay 요일지정
            autoPlayInfo.week = [];
            if (result.sun === 'Y') autoPlayInfo.week.push(0); // 일요일
            if (result.mon === 'Y') autoPlayInfo.week.push(1); // 월요일
            if (result.tue === 'Y') autoPlayInfo.week.push(2); // 화요일
            if (result.wed === 'Y') autoPlayInfo.week.push(3); // 수요일
            if (result.thu === 'Y') autoPlayInfo.week.push(4); // 목요일
            if (result.fri === 'Y') autoPlayInfo.week.push(5); // 금요일
            if (result.sat === 'Y') autoPlayInfo.week.push(6); // 토요일
            // autoplay 시작시간
            autoPlayInfo.startHours = result.startTime.substring(0, 2);
            autoPlayInfo.startMinutes = result.startTime.substring(3, 5);
            // autoplay 종료시간
            autoPlayInfo.endHours = result.endTime.substring(0, 2);
            autoPlayInfo.endMinutes = result.endTime.substring(3, 5);
            // autoplay 사용여부
            autoPlayInfo.useAt = result.useAt;
            this.$store.commit('setAutoPlay', autoPlayInfo);

            this.setAutoPlay();
          }
        });
      }
    },
    /**
     * @description cronjob 삭제
     * @author CHOI DAE GEON
     */
    stopAutoPlay() {
      this.$crontab.deleteJob('startAutoPlay');
      this.$crontab.deleteJob('endAutoPlay');
    },
    closePopup(cheerInfo) {
      this.cheerSrc = cheerInfo.src;
      this.$store.commit('setCarCurationModal', false);
      this.$CarPlayer.setCarVideo();
      this.$CarPlayer.setVideoSrc(this.cheerSrc);
      this.$CarPlayer.setMute(false);
      this.$CarPlayer.onCarPurposePlay();
      this.$CarPlayer.registerOnceCarVideoEvent('ended', this.carPurPoseEnded);
      // this.init();
    },
    carPurPoseEnded() {
      console.log('car purpose music ended event');
      this.$CarPlayer.removeSrc();
      this.init();
    },
    async init() {
      const { isUseSpace } = await this.getUseSpace();
      this.getMySpaceInfo(); // freePlay 설정 이슈로 위치 이동 A1
      if (isUseSpace) {
        if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
          await this.$store.dispatch('player/setNonSpacePlay');

          this.onceSetChannelMusicInfo();
          await this.onceChannelInfo();
          this.setUserBookmarkChannelList();
          this.getGeoLocation();
          this.intervalRecommJob();
        } else if (this.getUserType === USER_TYPE.FREE_HAS_SPACE || this.getUserType === USER_TYPE.SUBSCRIBE) {
          this.intervalRecommJob();

          await this.$store.dispatch('player/createChannel', { retry: false });

          this.onceSetChannelMusicInfo();
          await this.onceChannelInfo();
          this.setPlayerInfo();
          this.setCurationInfo();
          this.allFixedChannels();
          this.setSimilarChannels();
          this.setBookmarkChannels();

          this.setIntervalGroup();

          this.$store.dispatch('player/addCronJob');

          if (this.spaceShowroomCheck === 'Y') {
            try {
              const screenLock = await navigator.wakeLock.request('screen');

              if (typeof screenLock !== 'undefined') {
                screenLock.release().then(() => {
                  console.log('Lock released 🎈');
                });

                screenLock.addEventListener('release', () => {
                  console.log('Lock released');
                });
              }
            } catch (error) {
              console.log('screenLock : ', `${error.name}, ${error.message}`);
            }
            this.showroomInterval = setInterval(() => {
              this.enterCheck();
            }, 5000);
          }
        } else if (this.getUserType === USER_TYPE.END_FREE) {
          const { spaceImg, spaceNm } = this.$store.getters['player/getSpaceInfo'];
          setBackGroundImg(spaceImg);
          this.$store.commit('setSpaceNm', spaceNm);
          this.$store.commit('player/setIsLoading', false);
          // 더미 음원 정보 세팅
          this.$store.commit('setChannelMusicInfo', {
            albumImg: '/media/img/player/dummymusicimg.png',
            artist: '뉴진스 (NewJeans)',
            musicId: '',
            musicNm: 'Ditto'
          });
          await this.$store.dispatch('player/settingCurationInfo');
        } else if (this.getUserType === USER_TYPE.END_FREE_NO_SPACE) {
          await this.$store.dispatch('player/setEndFreeSpace', {
            usertype: this.getUserType
          });
        }
      }
    },
    async enterCheck() {
      //
      if (this.$store.getters['player/getIsShowRoomChecking']) return;
      try {
        const spaceId = this.$cookies.get('spaceId');
        const { data: showRoomData } = await selectShowRoomEnterCheck(spaceId);

        const { result } = showRoomData;
        if (result) {
          this.$store.commit('player/setLoadingStatus', true);
          this.$store.commit('player/setIsShowRoomEnterCheck', true);

          await this.fadeOutSound();

          await updateShowRoomEnterCheckN(spaceId);
          this.$store.dispatch('player/showRoomUpdate');
        }
      } catch (error) {
        console.error('enterCheck error : ', error);
      }
    },
    setCurationInfo() {
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const curationId = this.$store.getters['player/getCreatorCurationId']
        ? this.$store.getters['player/getCreatorCurationId']
        : '';
      getSpaceCurationInfo(
        spaceId,
        {
          'X-AUTH-TOKEN': aToken
        },
        curationId
      ).then(res => {
        if (res.data.resultCd === '0000') {
          this.$store.commit('player/setCurationInfo', res.data.result);
        }
      });
    },
    async setIntervalGroup() {
      this.onceSetChannelMusicInfo();
      await this.onceChannelInfo();

      this.setBrandMusicList();
      this.getAutoPlayInfo();
      // interval
      this.setMusicInfoInterval();
      this.setChannelInfoInterval();
      // 1000 * 60 * 5
      this.infoInterval = setInterval(() => {
        this.getCurrentPosition();
        this.setPlayerInfo();
      }, 1000 * 60 * 5);
    },
    setAutoPlay() {
      this.stopAutoPlay();
      this.$crontab.addJob({
        name: 'counter',
        interval: {
          seconds: '/1'
        },
        job: this.countUp
      });
      const useAt = this.$store.state.autoPlayInfo.useAt;
      if (useAt === 'Y') {
        this.$crontab.addJob([
          {
            name: 'startAutoPlay',
            interval: {
              seconds: '0',
              minutes: this.$store.state.autoPlayInfo.startMinutes,
              hours: this.$store.state.autoPlayInfo.startHours,
              week: this.$store.state.autoPlayInfo.week.toString()
            },
            job: this.setPlay
          },
          {
            name: 'endAutoPlay',
            interval: {
              seconds: '0',
              minutes: this.$store.state.autoPlayInfo.endMinutes,
              hours: this.$store.state.autoPlayInfo.endHours,
              week: this.$store.state.autoPlayInfo.week.toString()
            },
            job: this.setPause
          }
        ]);
      }
    },
    setPlay() {
      const ios = !Hls.isSupported();
      const createChannelObject = {
        retry: true
      };
      if (ios) {
        createChannelObject.ios = true;
      }

      if (this.$store.state.channelId !== '') {
        this.$store.commit('setChannelId', '');
        this.$store.commit('setChannelUrl', null);
      }
      this.$store.dispatch('player/createChannel', createChannelObject);
      const list = this.findStartBrandId();
      if (list.length > 0) {
        // disable
        list.forEach(item => {
          this.$crontab.disableJob(item);
          const find = this.brandMusicInfoList.find(subItem => subItem.name === item);
          if (find) {
            this.$store.commit('setBrandPlayerList', find);
          }
        });

        this.brandMusicPlay(this.addStartAutoEventListener, this.brandIosAutoStart);
        // 활성화
        setTimeout(() => {
          list.forEach(item => {
            this.$crontab.enableJob(item);
          });
        }, 1000 * 60);
      } else {
        this.startAuto();
      }
    },
    setPause() {
      const list = this.findBrandId();
      if (list.length > 0) {
        // disable
        list.forEach(item => {
          this.$crontab.disableJob(item);
          const find = this.brandMusicInfoList.find(subItem => subItem.name === item);
          if (find) {
            this.$store.commit('setBrandPlayerList', find);
          }
        });

        this.brandMusicPlay(this.addEndAutoEventListener, this.brandIosAutoStop);

        // 활성화
        setTimeout(() => {
          list.forEach(item => {
            this.$crontab.enableJob(item);
          });
        }, 1000 * 60);
      } else {
        this.stopAuto();
      }
    },
    startAuto() {
      if (this.$store.state.channelId !== '') {
        this.$store.commit('setChannelId', '');
        this.$store.commit('setChannelUrl', null);
      }
      this.$store.dispatch('player/createChannel', { retry: true });
      this.setMusicInfoInterval();
    },
    stopAuto() {
      this.$VideoPlayer.onPause();
      this.$store.commit('setPlay', false);
      leaveChannel();
    },
    findStartBrandId() {
      const startJob = this.$crontab.jobs.startAutoPlay;
      const { hours: startHours, minutes: startMinutes } = startJob.intervals[0];

      const startItem = `${startHours}:${startMinutes}`;
      const filterBrand = Object.keys(this.$crontab.jobs).filter(item => item.includes('brand'));
      const brandList = [];
      for (const item in filterBrand) {
        const id = filterBrand[item];
        const { hours: brandHours, minutes: brandMinutes } = this.$crontab.jobs[id].intervals[0];
        const list = brandHours.split(',').map(brandItem => {
          const str = `${brandItem}:${brandMinutes}`;

          return str;
        });
        const find = list.find(item => item === startItem);
        if (find) {
          brandList.push(id);
        }
      }

      return brandList;
    },
    findBrandId() {
      const endJob = this.$crontab.jobs.endAutoPlay;
      const { hours: endHours, minutes: endMinutes } = endJob.intervals[0];
      const endItem = `${endHours}:${endMinutes}`;
      const filterBrand = Object.keys(this.$crontab.jobs).filter(
        item => item.includes('brand') || item.includes('mysound')
      );
      const brandList = [];
      for (const item in filterBrand) {
        const id = filterBrand[item];
        const { hours: brandHours, minutes: brandMinutes } = this.$crontab.jobs[id].intervals[0];
        const list = brandHours.split(',').map(brandItem => {
          const str = `${brandItem}:${brandMinutes}`;

          return str;
        });
        const find = list.find(item => item === endItem);

        if (find) {
          brandList.push(id);
        }
      }

      return brandList;
    },
    countUp() {
      this.counter += 1;
      this.$store.commit('setCounter', this.counter);
    },
    /**
     * @description 브랜드 사운드
     * @author CHOI DAE GEON
     */
    async setBrandMusicList() {
      const spaceId = this.$cookies.get('spaceId');
      try {
        const { data } = await getBrandSpaceMusicList(spaceId);
        const { result, resultCd } = data;
        if (resultCd === '0000') {
          // result
          this.$store.commit('player/setBrandMusicList', result);
        }
      } catch (error) {
        console.error('getBrandSpaceMusicList error : ', error);
      } finally {
        await this.setMySoundList();
        this.setBroadCastInfoMusic();
      }
    },
    /**
     * @description 마이사운드 세팅
     * @author CHOI DAE GEON
     */
    async setMySoundInfoMusic() {
      await this.setMySoundList();
      this.setBroadCastInfoMusic();
    },
    /**
     * @description 마이사운드 리스트 적용
     * @author CHOI DAE GEON
     */
    async setMySoundList() {
      await this.$store.dispatch('player/settingMySoundList', {
        userId: this.$cookies.get('userId'),
        spaceId: this.$cookies.get('spaceId')
      });
    },
    /**
     * @description 방송/안내 cronTab적용
     * @author CHOI DAE GEON
     */
    setBroadCastInfoMusic() {
      this.brandMusicInfoList = [];
      this.deleteBrandMusicJob();

      const brandList = cloneDeep(this.$store.getters['player/getBrandMusicList']);
      const mysoundList = cloneDeep(this.$store.getters['player/getMySoundList']);

      // console.log(cloneDeep(this.$store.getters['player/getMySoundList']));
      let broadCastInfoList = [];
      if (Array.isArray(brandList)) {
        brandList.map(item => {
          item.type = 'BRAND';
          item.name = 'brand';
          return item;
        });
        broadCastInfoList = [...broadCastInfoList, ...brandList];
      }

      if (Array.isArray(mysoundList)) {
        mysoundList.map(item => {
          item.type = 'MYSOUND';
          item.name = 'mysound';
          return item;
        });
        broadCastInfoList = [...broadCastInfoList, ...mysoundList];
      }

      // 브랜드 리스트 정리
      broadCastInfoList.forEach(element => {
        if (element.playAt === 'Y') {
          const brandMusic = {
            // cronjob 등록을 위한 데이터 셋팅
            musicInfo: {
              musicType: element.type,
              albumImg: element.brandImg
                ? 'https://www.aplayz.co.kr/media/upload' + element.brandImg.substr(12)
                : 'https://www.aplayz.co.kr/media/img/emptyImg/Group_486440.png',
              artist: element.brandNm,
              musicNm: element.brandMusicNm,
              src: '/stream/getMusic?' + element.brandMusicId,
              musicId: element.brandMusicId
            },
            id: element.brandSpaceMusicId,
            name: element.name + element.brandSpaceMusicId,
            minutes: element.playType === 'S' ? element.selectTime.substring(3, 5) : element.startTime.substring(3, 5)
          };

          // 시간 타입(S:지정,R:반복)
          if (element.playType === 'S') {
            brandMusic.hours = element.selectTime.substring(0, 2);
          } else {
            const { repeatTime, startTime, endTime } = element;
            // Next

            const repeat = parseInt(repeatTime);
            const hours = [];
            const start = parseInt(startTime.split(':')[0]);
            const startMin = parseInt(startTime.split(':')[1]);
            const startTimeCalc = parseInt(start) * 60 + parseInt(startMin);

            const endHour = parseInt(endTime.split(':')[0]);
            const endMin = parseInt(endTime.split(':')[1]);
            const endTimeCalc = parseInt(endHour) * 60 + parseInt(endMin);
            const END_TIME = 60 * 23 + 55;
            const MAX_TIME = startTimeCalc >= endTimeCalc ? END_TIME : endTimeCalc;
            let i = start;
            for (i = start; parseInt(i) * 60 + startMin <= MAX_TIME; i += repeat) {
              hours.push(i > 9 ? i : `0${i}`);
            }

            i = i > 23 ? i - 24 : i;

            if (startTimeCalc >= endTimeCalc) {
              for (let index = i; parseInt(index) * 60 + startMin <= endTimeCalc; index += repeat) {
                hours.push(index > 9 ? index : `0${index}`);
              }
            }
            brandMusic.hours = hours.join(',');
          }
          this.brandMusicInfoList.push(brandMusic);
        }
      });

      // 애슐리퀸즈 공덕점
      const isBrandMusicHardCoding = this.brandMusicHardCodingList.find(item => item.spaceId === this.spaceId);

      // crontab 적용
      const MAX_COUNT = this.brandMusicInfoList.length > 30 ? 30 : this.brandMusicInfoList.length;

      for (let index = 0; index < MAX_COUNT; index++) {
        if (this.brandMusicInfoList[index] !== null) {
          let weekDay = '*';
          if (isBrandMusicHardCoding) {
            const { brandSpaceMusicId, week } = isBrandMusicHardCoding;
            const { id } = this.brandMusicInfoList[index];
            const isExistsId = brandSpaceMusicId.includes(id);
            if (isExistsId) {
              weekDay = week;
            }
          }

          this.$crontab.addJob({
            name: this.brandMusicInfoList[index].name, // cronjob name설정
            interval: {
              // 시간설정
              week: weekDay,
              seconds: (index + 1).toString(),
              minutes: this.brandMusicInfoList[index].minutes,
              hours: this.brandMusicInfoList[index].hours
            },
            job: () => {
              console.log('call spliceBrandMusic');
              if (this.$store.state.isPlay) {
                // 플레이 중일때만 브랜드 음원 재생
                this.$store.commit('setBrandPlayerList', this.brandMusicInfoList[index]);
                this.brandMusicPlay();
              }
            }
          });
        }
      }
    },
    async brandMusicPlay(addEventListener = this.addBrandEventListener, brandIosPlay = this.brandIosPlay) {
      const isMuted = this.$VideoPlayer.video.muted;
      if (!isMuted) {
        await this.fadeOutSound();
        this.$store.commit('setIsBrandMusic', true);
        this.$store.commit('setIsBrandMusicPlay', true); // 브랜드 음원 재생 true
      }
      if (this.$store.state.brandActiveIdx < 0) {
        if (!this.$store.getters.gettersIsBrandMusic || !this.$store.state.isBrandMusicPlay) {
          this.$store.commit('setIsBrandMusic', true);
          this.$store.commit('setIsBrandMusicPlay', true);
        }

        this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        if (Hls.isSupported() && !navigator.userAgent.includes('iPhone')) {
          this.$BrandPlayer.playBrandMusic(musicInfo.src);
          addEventListener();
        } else {
          brandIosPlay();
        }
      }
    },

    addBrandEventListener() {
      this.$BrandPlayer.registerBrandVideoOnceEvent('ended', this.brandMuiscEndedEvent);
      this.$BrandPlayer.registerBrandVideoOnceEvent('canplaythrough', this.brandMusicCanPlayEvent);
    },
    addEndAutoEventListener() {
      this.$BrandPlayer.registerBrandVideoOnceEvent('ended', this.brandSetAutoEndedEvent);
      this.$BrandPlayer.registerBrandVideoOnceEvent('canplaythrough', this.brandMusicCanPlayEvent);
    },
    addStartAutoEventListener() {
      this.$BrandPlayer.registerBrandVideoOnceEvent('ended', this.brandSetAutoStartEvent);
      this.$BrandPlayer.registerBrandVideoOnceEvent('canplaythrough', this.brandMusicCanPlayEvent);
    },
    async brandSetAutoStartEvent() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.$store.commit('setPlay', true);
        this.$BrandPlayer.playBrandMusic(musicInfo.src);

        if (Hls.isSupported()) {
          this.addStartAutoEventListener();
        }
        if (this.$store.getters['player/getIsMute']) {
          this.$BrandPlayer.setBrandVideoVolume(0);
        } else {
          this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
        }
      } else {
        console.log('ended brand video');
        this.$store.commit('setAllEndBrandMusic', {
          brandPlayerList: [],
          brandActiveIdx: -1,
          isBrandMusic: false
        });
        this.$VideoPlayer.setMuted(false);
        await this.fadeInSound();
        this.setMusicInfoInterval();
      }
    },
    async brandSetAutoEndedEvent() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.$BrandPlayer.playBrandMusic(musicInfo.src);

        if (Hls.isSupported()) {
          this.addEndAutoEventListener();
        }
        if (this.$store.getters['player/getIsMute']) {
          this.$BrandPlayer.setBrandVideoVolume(0);
        } else {
          this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
        }
      } else {
        console.log('ended brand video');
        this.$store.commit('setAllEndBrandMusic', {
          brandPlayerList: [],
          brandActiveIdx: -1,
          isBrandMusic: false
        });
        if (this.$store.state.isBrandMusicPlay) {
          this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
          this.stopAuto();
        }
      }
    },
    async brandMuiscEndedEvent() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.$BrandPlayer.playBrandMusic(musicInfo.src);
        if (Hls.isSupported()) {
          this.addBrandEventListener();
        }
        if (this.$store.getters['player/getIsMute']) {
          this.$BrandPlayer.setBrandVideoVolume(0);
        } else {
          this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
        }
      } else {
        console.log('ended brand video');
        this.$store.commit('setAllEndBrandMusic', {
          brandPlayerList: [],
          brandActiveIdx: -1,
          isBrandMusic: false
        });
        if (this.$store.state.isBrandMusicPlay) {
          this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
          this.$VideoPlayer.onMute(false);
          if (!this.$store.getters['player/getIsMute']) {
            await this.fadeInSound();
          }
        }
      }
    },
    /**
     * @description 브랜드음원 히스토리
     */
    insertBrandMusicHistory() {
      const brandActiveIdx = this.$store.getters.gettersBrandActiveIndex;
      if (brandActiveIdx > -1) {
        const { musicInfo } = this.$store.state.brandPlayerList[brandActiveIdx];
        const duration = this.$BrandPlayer.getDuration()
          ? this.$BrandPlayer.getDuration()
          : this.$VideoPlayer.getDuration();
        if (duration) {
          const convertMMHH = new Date(duration * 1000).toISOString().substr(14, 5);

          insertMusicPlayHistoryApi(musicInfo.musicId, convertMMHH);
        }
      }
    },
    brandMusicCanPlayEvent() {
      this.insertBrandMusicHistory();
      if (this.$store.getters['player/getIsMute']) {
        this.$BrandPlayer.setBrandVideoVolume(0);
      } else {
        this.$BrandPlayer.onBrandMute(false);
        this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
      }
      if (this.$BrandPlayer.getPaused()) {
        console.log('brand_video canplaythrough event trigger');

        const promise = this.$BrandPlayer.onBrandPlay();
        if (promise !== undefined) {
          promise
            .then(_ => {
              if (!this.$store.state.brandMusicMute) {
                this.$BrandPlayer.onBrandMute(false);
              } else {
                this.$BrandPlayer.onBrandMute(true);
              }

              // 자동재생 방지로 인한 메시지 또는 수동 재생버튼 클릭 메시지 필요
            })
            .catch(err => {
              console.error('Auto-play was prevented: ' + err);
            });
        }
      }
    },
    async brandIosPlay() {
      const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084' + musicInfo.src
          : musicInfo.src;
      this.$VideoPlayer.onPause();
      this.$VideoPlayer.setSource(tg);
      this.$VideoPlayer.videoLoad();
      this.$VideoPlayer.onPlay();
      this.$VideoPlayer.onMute(false);

      // this.brandEndedIosVideo
      this.$VideoPlayer.registerVideoOnceEvent('canplaythrough', this.brandCanplayIosVideo);
      this.$VideoPlayer.registerVideoOnceEvent('ended', this.brandEndedIosVideo);
    },
    async brandIosAutoStop() {
      const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084' + musicInfo.src
          : musicInfo.src;
      this.$VideoPlayer.onPause();
      this.$VideoPlayer.setSource(tg);
      this.$VideoPlayer.videoLoad();
      this.$VideoPlayer.onPlay();
      this.$VideoPlayer.onMute(false);

      this.$VideoPlayer.registerVideoOnceEvent('canplaythrough', this.brandCanplayIosVideo);
      this.$VideoPlayer.registerVideoOnceEvent('ended', this.brandEndedAutoPauseIosVideo);
    },
    async brandIosAutoStart() {
      const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084' + musicInfo.src
          : musicInfo.src;
      this.$VideoPlayer.onPause();
      this.$VideoPlayer.setSource(tg);
      this.$VideoPlayer.videoLoad();
      this.$VideoPlayer.onPlay();
      this.$VideoPlayer.onMute(false);

      this.$VideoPlayer.registerVideoOnceEvent('canplaythrough', this.brandCanplayIosVideo);
      this.$VideoPlayer.registerVideoOnceEvent('ended', this.brandEndedAutoStartIosVideo);
    },
    async brandCanplayIosVideo() {
      console.log('brandCanplayIosVideo Start');
      this.insertBrandMusicHistory();
      await this.$VideoPlayer.onPlay();
      this.$VideoPlayer.onMute(false);
    },
    async brandEndedAutoStartIosVideo() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.brandIosAutoStart();
        this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
      } else {
        console.log('ended brand song');
        this.$store.commit('setAllEndBrandMusic', {
          brandPlayerList: [],
          brandActiveIdx: -1,
          isBrandMusic: false
        });
        this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
        this.$VideoPlayer.startLoad(-1);
        this.$VideoPlayer.setMuted(false);
        await this.fadeInSound();
        this.setMusicInfoInterval();
      }
    },
    async brandEndedAutoPauseIosVideo() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.brandIosAutoStop();
        this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
      } else {
        console.log('ended brand song');

        this.$store.commit('setAllEndBrandMusic', {
          brandPlayerList: [],
          brandActiveIdx: -1,
          isBrandMusic: false
        }); //
        this.stopAuto();
      }
    },
    async brandEndedIosVideo() {
      this.$store.commit('setBrandActiveIdx', this.$store.state.brandActiveIdx + 1);
      if (this.$store.state.brandPlayerList.length > this.$store.state.brandActiveIdx) {
        const { musicInfo } = this.$store.state.brandPlayerList[this.$store.state.brandActiveIdx];
        this.$store.commit('setBrandMusicInfo', musicInfo);
        this.$VideoPlayer.onMute(true);
        this.brandIosPlay();
        this.$BrandPlayer.setBrandVideoVolume(parseFloat(this.$store.getters['player/getVolume']));
      } else {
        this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
        await this.playQurationMusic();
        this.$store.commit('setClearBrandPlayerList', []);
        this.$store.commit('setBrandActiveIdx', -1);
        this.$store.commit('setIsBrandMusic', false); // 브랜드 음원 값 false

        if (this.$store.state.isBrandMusicPlay) {
          // 브랜드 음원의 재생 값이 true일 경우
          this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
          this.$VideoPlayer.onMute(false);
          this.$VideoPlayer.setVolume(this.$store.state.volume);
        }
      }
    },
    playQurationMusic() {
      return new Promise((resolve, reject) => {
        this.$VideoPlayer.startLoad(-1);
        this.$VideoPlayer
          .onPlay()
          .then(() => {
            resolve('success');
            this.$VideoPlayer.onMute(false);
          })
          .catch(_ => {
            reject(new Error('play error'));
          });
      });
    },
    fadeOutSound(time = 500) {
      return new Promise(resolve => {
        const video = document.getElementById('video');
        const fadeoutInterval = setInterval(fadeout, time);

        function fadeout() {
          // Not IOS
          if (Hls.isSupported()) {
            if (video.volume > 0.1 && !navigator.userAgent.includes('iPhone')) {
              video.volume -= 0.1; // Decrease the volume by 10% every 500ms
            } else {
              video.volume = 0;
              video.muted = true;
              clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
              resolve();
            }
          } else {
            resolve();
          }
        }
      });
    },
    fadeInSound() {
      return new Promise(resolve => {
        const video = document.getElementById('video');
        const fadeoutInterval = setInterval(fadein, 500);
        const volume = parseFloat(this.$store.getters['player/getVolume']);
        function fadein() {
          // Not IOS
          if (Hls.isSupported()) {
            if (video.volume < volume) {
              const val = video.volume + 0.1;
              if (val > 1) {
                video.volume = 1;
              } else {
                video.volume = val; // Decrease the volume by 10% every 500ms
              }
            } else {
              clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
              resolve();
            }
          } else {
            resolve();
          }
        }
      });
    },
    /**
     * @description crontab에 brand로 등록된 job 삭제
     * @author CHOI DAE GEON
     */
    deleteBrandMusicJob() {
      const array = Object.keys(this.$crontab.jobs);
      const deletArray = array.filter(job => {
        return job.includes('mysound') || job.includes('brand');
      });

      for (const j in deletArray) {
        this.$crontab.deleteJob(deletArray[j]);
      }
    },
    async hlsErrorHandler(_, data) {
      const _store = this.$store;
      if (document.location.hostname === 'www.aplayz.co.kr') {
        (window.gtag || function() {})('event', 'hls_error', {
          space_id: _store.state.spaceId,
          space_nm: _store.state.spaceNm,
          is_fatal: data.fatal,
          error_type: data.type,
          error_detail: data.details
        });
      }

      if (data.fatal) {
        this.$gatag.event('hls_error', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId,
          hls_error: data
        });

        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            if (data.details === 'manifestLoadError') {
              this.$store.commit('setChannelId', '');
              this.$store.commit('setPlay', false);
              this.$store.commit('player/setChannelStatus', 'restart');
              this.$store.commit('player/setLoadingStatus', true);
              await this.$store.dispatch('player/createChannel', { retry: true });
            } else if (data.details === 'levelLoadError') {
              if (this.$store.state.isPlay && !this.$store.state.isBrandMusic) {
                this.$store.commit('setChannelId', '');
                console.log('start restart');
                this.$store.commit('player/setChannelStatus', 'restart');
                this.$store.commit('player/setLoadingStatus', true);
                await this.$store.dispatch('player/createChannel', { retry: true });
                this.allFixedChannels();
              }
            }
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            break;
          default:
            // cannot recover fatal errors go next song
            this.$VideoPlayer.videoDisPatchEvent('ended');
            break;
        }
      }
    },
    setManageBookMark(channelId) {
      const addArray = [
        ...this.$store.getters['player/getPopularityChannelList'],
        ...this.$store.getters['player/getDesignatedRecommendedChannels']
      ];
      const findChannelId = addArray.find(item => item.channelId === channelId);
      const recomId = findChannelId.recomId;
      if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
        this.$store.dispatch('player/manageNonSpaceBookmark', {
          recomId
        });
      } else {
        this.$store.dispatch('player/manageBookmark', {
          channelId: recomId
        });
      }
    },
    setSimilarChannels() {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId) {
        selectSimilarChannels(spaceId).then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            this.$store.commit('player/setSimilarChannelList', result);
          }
        });
      }
    },
    similarChannelInterval() {
      this.similarInterval = setInterval(this.setSimilarChannels, 1000 * 5);
      this.timeOutId = setTimeout(() => {
        clearInterval(this.similarInterval);
        this.similarInterval = setInterval(this.setSimilarChannels, 1000 * 60);
      }, 1000 * 60);
    },
    setBookmarkChannels() {
      const spaceId = this.$cookies.get('spaceId');
      selectBookmarkChannels(spaceId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.$store.commit('player/setBookmarkChannels', result);
        }
      });
    },
    setUserBookmarkChannelList() {
      const userId = this.$cookies.get('userId');
      selectUserBookmarkChannelList(userId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.$store.commit('player/setBookmarkChannels', result);
        }
      });
    },
    async allExperienceUserChannel() {
      const userId = this.$cookies.get('userId');

      try {
        const { data: userFixData } = await userFixedChannel(userId);
        const { resultCd: userFixResultCd, result: userFixResult } = userFixData;
        if (userFixResultCd === '0000') {
          const { otherChannels, recomChannels } = userFixResult;
          this.$store.commit('player/setDesignatedRecommendedChannels', otherChannels);
          this.$store.commit('player/setPopularityChannelList', recomChannels);
        } else {
          this.$store.commit('player/setDesignatedRecommendedChannels', []);
          this.$store.commit('player/setPopularityChannelList', []);
        }
      } catch (error) {
        this.$store.commit('player/setDesignatedRecommendedChannels', []);
        this.$store.commit('player/setPopularityChannelList', []);
      }
    },
    allFixedChannels() {
      const spaceId = this.$cookies.get('spaceId');
      if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
        this.allExperienceUserChannel();
      } else if (this.getUserType === USER_TYPE.SUBSCRIBE || this.getUserType === USER_TYPE.FREE_HAS_SPACE) {
        selectAllChannel(spaceId)
          .then(res => {
            const { data } = res;
            const { resultCd, result } = data;
            if (resultCd === '0000') {
              const { otherChannels, recomChannels } = result;
              this.$store.commit('player/setDesignatedRecommendedChannels', otherChannels);
              this.$store.commit('player/setPopularityChannelList', recomChannels);
            } else {
              //
              this.$store.commit('player/setDesignatedRecommendedChannels', []);
              this.$store.commit('player/setPopularityChannelList', []);
            }
          })
          .catch(() => {
            this.$store.commit('player/setDesignatedRecommendedChannels', []);
            this.$store.commit('player/setPopularityChannelList', []);
          });
      }
    },

    designatedRecommendedChannels() {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId) {
        selectDesignatedRecommendedChannels(spaceId)
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              this.$store.commit('player/setDesignatedRecommendedChannels', result);
            } else {
              this.$store.commit('player/setDesignatedRecommendedChannels', []);
            }
          })
          .catch(err => {
            console.log('popularityChannels error : ', err);
          });
      }
    },
    popularityChannels() {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId) {
        selectPopularityChannels(spaceId)
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              this.$store.commit('player/setPopularityChannelList', result);
            } else {
              this.$store.commit('player/setPopularityChannelList', []);
            }
          })
          .catch(err => {
            console.log('popularityChannels error : ', err);
          });
      }
    },
    getCurrentPosition() {
      if (this.$store.getters['player/getSpaceInfo']) {
        const { spaceType } = this.$store.getters['player/getSpaceInfo'];
        if (spaceType === 'Car') {
          this.getGeoLocation();
        }
      }
    },
    getGeoLocation() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude; // 위도
          this.lon = position.coords.longitude; // 경도
        },
        error => {
          const message = error.message;
          if (message === 'User denied Geolocation') {
            // 위치정보 허용 거절한 경우 서울 중심값으로 셋팅
            this.lat = 37.566826;
            this.lon = 126.9786567;
          } else {
            console.log(message);
          }
        }
      );
    },
    async onceChannelInfo() {
      if (this.$store.state.channelId) {
        const spaceId = this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : null;
        await getChannelInfo(this.$store.state.channelId, spaceId).then(res => {
          const { data } = res;
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            this.$store.commit('player/setChannelInfo', result);
          }
        });
      }
    },
    setOpen(index, isOpen) {
      this.$store.commit('player/setTagIsOpenChange', {
        index,
        isOpen: !isOpen
      });
    },
    reSelectRecommend() {
      this.$store.commit('player/setAlertStatus', false);
      // 이런 채널은 어때요
      // 인기 급상승 조회
      this.allFixedChannels();
      this.setBookmarkChannels();

      this.recommInterval = setInterval(() => {
        this.allFixedChannels();
        this.setBookmarkChannels();
      }, 1000 * 60);

      this.recommTimeOut = setTimeout(() => {
        clearTimeout(this.recommInterval);
      }, 1000 * 60 * 10);
    },
    closeAlertModal() {
      this.$store.commit('player/setIsAlertModal', false);
      this.$store.commit('player/setAlertText', '');
    },
    /**
     * @description 음원 정보 세팅
     */
    onceSetChannelMusicInfo() {
      const channelId = this.$store.state.channelId;
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      getChannelMusicInfo(channelId, headers)
        .then(res => {
          if (res.data) {
            const result = res.data.result;
            this.$store.commit('setChannelMusicInfo', result);
            this.checkLikeInfo();
          }
        })
        .catch(err => {
          this.$store.commit('setChannelMusicInfo', null);
          console.log('setChannelMusicInfo err : ', err);
        });
    },
    /**
     * @description 좋아요 클릭
     */
    async onClickLike() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        this.showHate = false;
        const { like } = this.$store.getters.getLikeInfo;
        if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
          const { weatherCode } = await this.getWeatherCode();
          const params = {
            channelId: this.$store.state.channelId,
            like: !like ? 'Y' : null,
            musicId: this.$store.state.channelMusicInfo.musicId,
            userId: this.$cookies.get('userId'),
            weatherCode
          };
          // 좋아요/싫어요 클릭
          insertUserMusicLikeOrDislike(params);
        } else {
          setLikeMusic({
            spaceId: this.$cookies.get('spaceId'),
            musicPath: this.$store.state.channelMusicInfo.musicId,
            like: 'Y',
            weatherCode: this.$store.getters['player/getWeatherInfo'].currentWeather,
            channelId: this.$store.state.channelId
          });
        }
        if (like === 'Y') {
          this.$store.commit('setLikeInfo', {
            like: 'N',
            unlike: null
          });
          this.showLike = false;
        } else {
          this.showLike = false;
          this.showLike = true;
          this.$store.commit('setLikeInfo', {
            like: 'Y',
            unlike: null
          });
        }

        this.$gatag.event('click_like', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId
        });
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    /**
     * @description 싫어요 클릭
     */
    async onClickHate() {
      this.showLike = false;
      const { unlike } = this.$store.getters.getLikeInfo;
      if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
        // 좋아요/싫어요 클릭
        const { weatherCode } = await this.getWeatherCode();

        const params = {
          channelId: this.$store.state.channelId,
          like: unlike === 'Y' ? null : 'N',
          musicId: this.$store.state.channelMusicInfo.musicId,
          userId: this.$cookies.get('userId'),
          weatherCode
        };
        insertUserMusicLikeOrDislike(params);
      } else {
        setLikeMusic({
          spaceId: this.$cookies.get('spaceId'),
          musicPath: this.$store.state.channelMusicInfo.musicId,
          unlike: 'Y',
          weatherCode: this.$store.getters['player/getWeatherInfo'].currentWeather,
          channelId: this.$store.state.channelId
        });
      }

      if (unlike === 'Y') {
        this.$store.commit('setLikeInfo', {
          like: null,
          unlike: 'N'
        });
      } else {
        this.showHate = false;
        this.showHate = true;
        this.$store.commit('setLikeInfo', {
          like: null,
          unlike: 'Y'
        });
      }

      this.$gatag.event('click_unlike', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    async getWeatherCode() {
      try {
        const { data } = await getCurrentWeather(this.lat, this.lon, '');
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          return {
            weatherCode: result.weatherCode
          };
        } else {
          return { weatherCode: '1' };
        }
      } catch (error) {
        console.error('getWeatherCode : ', console.error);
        return {
          weatherCode: '1'
        };
      }
    },
    /**
     * @description 한줄평 클릭
     */
    onClickComment() {
      this.isComment = true;
      this.$gatag.event('click_comment', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    /**
     * @description 신고하기 클릭
     */
    onClickReport() {
      this.isReport = true;
      this.$gatag.event('click_report', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    /**
     * @description 좋아요 닫기 클릭
     */
    onCloseLike() {
      this.showLike = false;
    },
    /**
     * @description 싫어요 닫기 클릭
     */
    onCloseHate() {
      this.showHate = false;
    },
    /**
     * @description 음원정보 주기적 호출
     */
    onSetMusicInfoInterval() {
      this.setChannelMusicInfo();
      this.setMusicInfoInterval();
    },
    setChannelInfoInterval() {
      if (this.channelInfoInterval === -1) {
        this.channelInfoInterval = setInterval(() => {
          if (this.$store.state.channelId) {
            const spaceId = this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : null;
            getChannelInfo(this.$store.state.channelId, spaceId).then(res => {
              const { data } = res;
              const { resultCd, result } = data;
              if (resultCd === '0000') {
                this.$store.commit('player/setChannelInfo', result);
              }
            });
          }
        }, 5000);
      }
    },
    setMusicInfoInterval() {
      clearInterval(this.$store.state.musicInfoInterval);
      this.$store.commit('setMusicInfoInterval', -1);
      if (this.$store.state.musicInfoInterval === -1) {
        const musicInfoInterval = setInterval(() => {
          if (this.$store.state.channelId !== '') this.setChannelMusicInfo();
        }, 5000);
        this.$store.commit('setMusicInfoInterval', musicInfoInterval);
      }
    },
    /**
     * @description 백그라운드 설정
     * @param {*} url
     */
    setBg() {
      const { name } = this.$route;
      if (name === 'PlayerView') {
        const bgImg = document.querySelector('.bg_img');
        if (this.$store.getters['player/getChannelInfo']) {
          const { channelType, channelId: selectChannelId } = this.$store.getters['player/getChannelInfo'];
          if (channelType === 'B') {
            let spaceImg = '';

            const { spaceImg: mySpaceImg, channelId: myChannelId } = this.$store.getters['player/getMyChannelInfo'];
            if (selectChannelId === myChannelId) {
              spaceImg = mySpaceImg;
            } else {
              const findSimlarChannelItem = this.$store.getters['player/getSimilarChannelList'].find(
                item => item.channelId === selectChannelId
              );
              if (findSimlarChannelItem) {
                const { spaceImg: findSpaceImg } = findSimlarChannelItem;
                spaceImg = findSpaceImg;
              }
            }

            bgImg.src = spaceImg;
          } else if (channelType === 'T') {
            const newArray = [
              ...this.$store.getters['player/getPopularityChannelList'],
              ...this.$store.getters['player/getDesignatedRecommendedChannels']
            ];
            const { recomId } = this.$store.getters['player/getChannelInfo'];
            const fineChannel = newArray.find(item => item.recomId === recomId);
            document.body.id = '';
            bgImg.src = `${this.baseUrl}${fineChannel.imgUrl}`;
          }
        }
      }
    },
    setNonSpaceChangeChannel() {
      this.setBg();
      this.checkLikeInfo();
    },
    /**
     * @description 클리어 백그라운드
     */
    clearBg() {
      const bgImg = document.querySelector('.bg_img');
      bgImg.src = '';
    },
    /**
     * @description 채널의 현재 음원 정보 가져오기
     * @author CHOI DAE GEON
     */
    async setChannelMusicInfo() {
      const channelId = this.$store.state.channelId;
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      getChannelMusicInfo(channelId, headers)
        .then(async res => {
          const result = res.data.result;
          if (this.$store.state.channelMusicInfo || this.$store.state.channelMusicInfo === null) {
            if (
              this.$store.state.channelMusicInfo === null ||
              result.musicId !== this.$store.state.channelMusicInfo.musicId
            ) {
              this.$gatag.event('music_playing', {
                space_id: this.$store.state.spaceId,
                space_nm: this.$store.state.spaceNm,
                artist: result.artist,
                music_nm: result.musicNm
              });
              await this.getSpacePayAt();
              this.$store.commit('setChannelMusicInfo', result);
              this.checkLikeInfo();
              this.checkDupUse();
            }
          }
        })
        .catch(err => {
          console.log('setChannelMusicInfo err : ', err);
        });
    },
    /**
     * @description 좋아요/싫어요 정보
     *
     */
    checkLikeInfo() {
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const userId = this.$cookies.get('userId');
      const headers = { 'X-AUTH-TOKEN': aToken };
      if (spaceId && this.$store.state.channelMusicInfo) {
        const musicPath = this.$store.state.channelMusicInfo.musicId;
        checkLike(spaceId, userId, musicPath, headers).then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            const likeInfo = {
              like: result.like,
              unlike: result.unlike
            };
            this.$store.commit('setLikeInfo', likeInfo);
          }
        });
      } else {
        getUserMusicLikeOrDislike({
          channelId: this.$store.state.channelId,
          musicId: this.$store.state.channelMusicInfo.musicId,
          userId
        }).then(res => {
          const { result } = res.data;
          let likeInfo = {
            like: null,
            unlike: null
          };
          if (result === 'Y') {
            likeInfo = {
              like: 'Y',
              unlike: null
            };
          } else if (result === 'N') {
            likeInfo = {
              like: null,
              unlike: 'Y'
            };
          }
          this.$store.commit('setLikeInfo', likeInfo);
        });
      }
    },
    checkDupUse() {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      const sessionId = localStorage.getItem('sessionId');
      const recentSpaceId = localStorage.getItem('recentSpaceId');
      if (spaceId) {
        checkDupleUse(userId, spaceId, sessionId, recentSpaceId)
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const { resultCd: spaceResultCd, sessionId } = result;
              localStorage.setItem('sessionId', sessionId);
              if (spaceResultCd === 'D0002') {
                this.$VideoPlayer.onPause();
                this.$store.commit('setSpaceConnection', true);
                this.$store.commit('setPlay', false);
              } else if (spaceResultCd === 'D0003') {
                localStorage.setItem('recentSpaceId', spaceId);
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async changeWeather() {
      const { weatherGb } = this.$store.getters['player/getWeatherInfo'];
      const { name } = this.$route;
      if (name === 'PlayerView') {
        if (weatherGb === 'rain') {
          this.$nextTick(() => {
            rainAnimation.init();
            rainAnimation.animate();
            window.addEventListener('resize', rainAnimation.resize.bind(rainAnimation));
          });
        } else if (weatherGb !== 'snow') {
          await this.$nextTick();
          if (document.querySelector('#particles-js')) {
            document.querySelector('#particles-js').style.display = 'none';
          }
        } else if (weatherGb === 'snow') {
          await this.$nextTick();
          document.querySelector('#particles-js').style.display = '';
        }
      }
    },
    /**
     * @description 큐레이션 정보 세팅
     */
    setPlayerInfo() {
      this.$store.commit('setPreInfo', this.$store.state.currentInfo);
      const spaceId = this.$cookies.get('spaceId');
      let lat = 37.566826;
      let lon = 126.9786567;
      if (this.lat != null) {
        // 위도 경도값을 못가져왔을때 서울 중심 위치로 고정
        lat = this.lat;
        lon = this.lon;
      }
      const weather = this.$store.state.selectWeatherAndTime.weather;
      const time = this.$store.state.selectWeatherAndTime.times;
      if (spaceId) {
        getPlayerInfo(spaceId, lon, lat, weather, time).then(res => {
          const { result } = res.data;
          this.$store.commit('player/setPlayerInfo', result);
          this.$store.commit('setSpaceNm', result.spaceNm);
          const currentInfo = {
            weather: this.$store.getters['player/getPlayerInfo'].currentWeather,
            times: this.$store.getters['player/getPlayerInfo'].currentTimes
          };
          const weatherInfo = {
            id: this.getWeatherId(
              this.$store.getters['player/getPlayerInfo'].currentTimes,
              this.$store.getters['player/getPlayerInfo'].currentWeather
            ),
            currentTimes: this.$store.getters['player/getPlayerInfo'].currentTimes,
            currentWeather: this.$store.getters['player/getPlayerInfo'].currentWeather,
            weatherGb: this.getWeather(this.$store.getters['player/getPlayerInfo'].currentWeather)
          };
          this.$store.commit('setCurrentInfo', currentInfo);
          this.$store.commit('player/setWeatherInfo', weatherInfo);

          this.setBg();
          this.changeWeather();
          if (result.message) {
            this.$VideoPlayer.onPause();
          }
        });
      }
    },
    /**
     * @description 날씨정보
     * @author CHOI DAE GEON
     */
    getWeather(weatherValue = '1') {
      let weather = 'sunny';
      if (weatherValue === '1') {
        weather = 'sunny';
      } else if (weatherValue === '2') {
        weather = 'cloud';
      } else if (weatherValue === '3') {
        weather = 'rain';
      } else if (weatherValue === '4') {
        weather = 'snow';
      }
      return weather;
    },
    /**
     * @description 날씨정보에 따른 날씨 id
     * @author CHOI DAE GEON
     */
    getWeatherId(times = '낮', weather = '1') {
      let weatherId = 'afternoon';
      if ((times === '낮' && weather === '1') || (times === '낮' && weather === '4')) {
        weatherId = 'afternoon';
      } else if (times === '낮' && weather === '2') {
        weatherId = 'afternoonCloud';
      } else if (times === '낮' && weather === '3') {
        weatherId = 'afternoonRain';
      } else if ((times === '저녁' && weather === '1') || (times === '저녁' && weather === '4')) {
        weatherId = 'dinner';
      } else if (times === '저녁' && weather === '2') {
        weatherId = 'dinnerCloud';
      } else if (times === '저녁' && weather === '3') {
        weatherId = 'dinnerRain';
      } else if ((times === '아침' && weather === '1') || (times === '아침' && weather === '4')) {
        weatherId = 'mornig';
      } else if (times === '아침' && weather === '2') {
        weatherId = 'mornigCloud';
      } else if (times === '아침' && weather === '3') {
        weatherId = 'mornigRain';
      } else if ((times === '밤' && weather === '1') || (times === '밤' && weather === '4')) {
        weatherId = 'night';
      } else if (times === '밤' && weather === '2') {
        weatherId = 'nightCloud';
      } else if (times === '밤' && weather === '3') {
        weatherId = 'nightRain';
      }

      return weatherId;
    },
    onPlayBtn() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        const brandActiveIdx = this.$store.getters.gettersBrandActiveIndex;
        if (brandActiveIdx > -1) {
          if (this.$BrandPlayer.getMuted()) {
            this.$BrandPlayer.onBrandMute(false);
            this.$store.commit('setBrandMusicMute', false);
          } else {
            this.$BrandPlayer.onBrandMute(true);
            this.$store.commit('setBrandMusicMute', true);
          }
        } else {
          if (this.$store.state.channelId) {
            if (this.$store.state.isPlay) {
              clearInterval(this.$store.state.musicInfoInterval);
              this.$VideoPlayer.onPause();
              this.$store.commit('setMusicInfoInterval', -1);
              this.$gatag.event('click_pause_mo', {
                space_id: this.$store.state.spaceId,
                space_nm: this.$store.state.spaceNm,
                channel_id: this.$store.state.channelId
              });
            } else {
              this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
              this.$VideoPlayer.startLoad(-1);
              this.$VideoPlayer.onPlay().then(() => {
                this.onSetMusicInfoInterval();
              });
              this.$gatag.event('click_play_mo', {
                space_id: this.$store.state.spaceId,
                space_nm: this.$store.state.spaceNm,
                channel_id: this.$store.state.channelId
              });
            }
          } else {
            this.$store.dispatch('player/createChannel');
          }
        }
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    async clickChannel(item, type) {
      let result = null;
      if (this.getUserType === USER_TYPE.FREE_NO_SPACE) {
        item.playerinfofn = this.setNonSpaceChangeChannel;
        result = await this.$store.dispatch('player/setNonSpaceJoinChannel', item);
      } else if (this.getUserType === USER_TYPE.SUBSCRIBE || this.getUserType === USER_TYPE.FREE_HAS_SPACE) {
        item.playerinfofn = this.setPlayerInfo;
        result = await this.$store.dispatch('player/joinChannel', item);
        this.onSetMusicInfoInterval();
      }

      if (result) {
        this.$store.commit('player/setActiveTab', type);
        this.$store.commit('player/setStartChannelInfo', {
          ...item,
          activeTab: type
        });
      }
      this.$gatag.event('click_joinChannel', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    openCuration() {
      const settingSpaceYn = this.$store.getters['player/getSettingSpaceYn'];
      if (settingSpaceYn === 'N') {
        const timeCurationList = this.$store.getters['player/getTimeCurationList'];
        const creatorCurationId = this.$store.getters['player/getCreatorCurationId'];
        const findCurationId = timeCurationList.find(item => item.curationId === creatorCurationId);
        if (findCurationId) {
          const { startTime, endTime } = findCurationId;
          if (startTime && endTime) {
            //
            const startSplit = startTime.split(':');
            const endSplit = endTime.split(':');
            const startHour = startSplit[0];
            const startMin = startSplit[1];
            const endHour = endSplit[0];
            const endMin = endSplit[1];
            const startAmPm = parseInt(startHour) > 12 ? '오후' : '오전';
            const endAmPm = parseInt(endHour) > 12 ? '오후' : '오전';
            const startTitle = `${
              parseInt(startHour) - 12 > 0 ? parseInt(startHour) - 12 : parseInt(startHour)
            }:${startMin}`;
            const endTitle = `${parseInt(endHour) - 12 > 0 ? parseInt(endHour) - 12 : parseInt(endHour)}:${endMin}`;
            this.settingCurationTitle = `${startAmPm} ${startTitle}~ ${endAmPm} ${endTitle}`;
            this.isShowEqualizer = true;
          } else {
            this.settingCurationTitle = `시간대별 큐레이션을 설정해보세요`;
            this.isShowEqualizer = false;
          }
        }
        // const find
        this.$store.commit('player/setIsOpenCurationSetting', true);
      } else {
        this.$store.dispatch('player/refreshChannel', {
          retry: false,
          playerinfofn: this.playerinfofn
        });
      }
      this.$gatag.event('click_curation', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    linkPayment() {
      localStorage.setItem('spaceId', this.$cookies.get('spaceId'));
      this.$router.push({ name: 'SpacePayment' }).catch(() => {});
    },

    goSpaceInsert() {
      this.$router.push({ name: 'SpaceInsert' }).catch(() => {});
    },
    onClickIsMobileMusicInfo() {
      if (this.getUserType !== USER_TYPE.END_FREE && this.getUserType !== USER_TYPE.END_FREE_NO_SPACE) {
        this.isMobileMusicInfo = 'active';
      } else {
        this.isSubscribeSpaceModal = true;
      }
    },
    /**
     * @description 무료 쿠폰 이미지/ 음원 파일
     */
    async getSelectExternalEventApi() {
      await selectExternalEvent().then(res => {
        if (res.data.resultCd === '0000') {
          if (res.data.result) {
            const info = res.data.result[0];
            if (info) {
              this.$freePlayer.setFreeVedioInit(info.ttsUrl);
              this.freePlayerImg = info.imageUrl;
              this.eventInfo = info;
            }
          }
        }
      });
    },
    // 이벤트 클릭
    async updateEventClickCountApi() {
      // q1
      const eventId = this.eventInfo.eventId;
      await updateEventClickCount(eventId)
        .then(res => {
          if (res.data.resultCd === '0000') {
            console.log(res.data.result);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    addJobFreePlayer() {
      // A1
      console.log('add cronjob');

      const minToString = '' + new Date().getMinutes();
      this.$crontab.addJob({
        name: 'FreePlayer', // cronjob name설정
        interval: {
          // seconds: '/15'
          seconds: '0',
          minutes: minToString,
          hours: '/1'
        },
        job: () => {
          if (this.$store.state.isPlay || !this.$store.state.isBrandMusicPlay) this.onFreePlayer();
        }
      });
    },
    async onFreePlayer() {
      const endEvent = new Date('2024-09-19 23:59');
      const curDate = new Date();

      if (curDate.getTime() > endEvent.getTime()) {
        return this.$crontab.deleteJob('FreePlayer');
      }
      console.log(new Date(), 'call FreeMusic');
      await this.fadeOutSound(50);
      this.$freePlayer.onFreePause();
      this.$freePlayer.onFreeIsMute(false);
      this.$freePlayer.onFreePlay();
      this.isFreeMusicPlaying = true;
      this.$freePlayer.registerFreeVideoOnceEvent('ended', this.reStartVideoPlay);
    },
    async reStartVideoPlay() {
      this.isFreeMusicPlaying = false;
      this.$VideoPlayer.setMuted(false);
      await this.fadeInSound();
    },
    async getMySpaceInfo() {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId !== null) {
        mySpaceInfo(spaceId).then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            this.$store.commit('player/setSpaceInfo', result);
            this.$store.commit('setSpacePayAt', result.spacePayAt);
            this.$store.commit('player/setSettingSpaceYn', result.settingSpaceYn);
            this.spaceShowroomCheck = result.spaceShowroomCheck;
            // coupon 유저 무료 광고 분기
            // this.getSelectExternalEventApi(); // 광고 mp3, 이미지 요청,
            // if (result.spaceType === 'Store' && result.paymentMethod === 'coupon') {
            //  this.isFreeCouponUser = true;
            // }
          }
        });
      } else {
        try {
          const result = await this.getUseSpace();
          if (!result.isUseSpace) {
            this.warningAlert();
          }
        } catch (error) {
          this.warningAlert();
          console.error('error : ', error);
        }
      }
    },
    async getSpacePayAt() {
      // 미구독 체크하여 마이스페이스로 화면 이동 작업
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId !== null) {
        mySpaceInfo(spaceId).then(async res => {
          const { resultCd, result } = res.data;
          const spacePayAtVal = result.spacePayAt;
          if (resultCd === '0000') {
            if (spacePayAtVal === 'N') {
              await this.fadeOutSound();
              this.$VideoPlayer.onPause();
              this.$VideoPlayer.playerMusic('');
              this.$cookies.remove('spaceId');
              this.$router.push({ name: 'MySpace' });
            }
          }
        });
      } else {
        console.log('spaceId is null');
      }
    }
  },
  computed: {
    isLike() {
      const { like } = this.$store.getters.getLikeInfo;
      if (like === 'Y') {
        return true;
      }

      return false;
    },
    isUnLike() {
      const { unlike } = this.$store.getters.getLikeInfo;
      if (unlike === 'Y') {
        return true;
      }
      return false;
    },
    brandMusicInfo() {
      return this.$store.getters.gettersBrandMusicInfo;
    },
    getMusicInfo() {
      return this.$store.getters.gettersChannelMusicInfo;
    },
    isPlayBrandMusic() {
      return this.$store.getters.gettersIsBrandMusic;
    },
    isCarCurationModal() {
      return this.$store.getters.getCarCurationModal;
    },
    spacename() {
      const newArray = [
        this.$store.getters['player/getMyChannelInfo'],
        ...this.$store.getters['player/getSimilarChannelList'],
        ...this.$store.getters['player/getPopularityChannelList'],
        ...this.$store.getters['player/getDesignatedRecommendedChannels']
      ];
      if (this.$store.state.channelId !== '') {
        const find = newArray.find(item => item && item.channelId === this.$store.state.channelId);
        if (find) {
          return find.spaceNm;
        } else {
          return this.$store.getters.getSpaceNm;
        }
      }
      return this.$store.getters.getSpaceNm;
    },
    spaceType() {
      if (this.$store.getters['player/getSpaceInfo']) {
        return this.$store.getters['player/getSpaceInfo'].spaceType;
      }
      return '';
    },
    expireDate() {
      if (this.$store.getters['player/getSpaceInfo']) {
        return this.$store.getters['player/getSpaceInfo'].expireDate
          ? this.$store.getters['player/getSpaceInfo'].expireDate.substr(0, 11).concat('23:59:59.9')
          : '';
      }
      return '';
    },
    alertStatus() {
      return this.$store.getters['player/getAlertStatus'];
    },
    tagList() {
      if (this.$store.getters['player/getChannelInfo']) {
        const { channelType } = this.$store.getters['player/getChannelInfo'];
        if (channelType === 'B') {
          const { targetList, targetUnitList, domesticRate, genreList, periodList } = this.$store.getters[
            'player/getChannelInfo'
          ];
          periodList.map(item => `${item}s`);
          return [targetList, targetUnitList, `${domesticRate}%`, genreList, periodList];
        } else {
          return [];
        }
      }
      return [];
    },
    channelType() {
      if (this.$store.getters['player/getChannelInfo']) {
        const { channelType } = this.$store.getters['player/getChannelInfo'];
        return channelType;
      }

      return 'B';
    },
    weatherInfo() {
      return this.$store.getters['player/getWeatherInfo'];
    },
    isBookmarkCheck() {
      const addArray = [
        ...this.$store.getters['player/getPopularityChannelList'],
        ...this.$store.getters['player/getDesignatedRecommendedChannels']
      ];

      const findChannel = addArray.find(item => item.channelId === this.$store.state.channelId);
      if (findChannel) {
        const { recomId } = findChannel;
        const find = this.$store.getters['player/getBookmarkChannels'].find(item => item.recomId === recomId);
        if (find) {
          return true;
        }

        return false;
      }
      return false;
    },
    isOpenAnim() {
      return this.$store.getters['player/getIsOpenAnim'];
    },
    isOpenCuration() {
      return this.$store.getters['player/getIsOpenCurationSetting'];
    },
    isAlertModal() {
      return this.$store.getters['player/getIsAlertModal'];
    },
    alertText() {
      return this.$store.getters['player/getAlertText'];
    },
    brandMusic() {
      return this.$store.getters['player/getBrandMusicList'];
    },
    myChannelInfo() {
      return this.$store.getters['player/getMyChannelInfo'];
    },
    isPlayMusic() {
      if (this.$store.state.brandActiveIdx > -1) {
        return !this.$store.state.brandMusicMute;
      } else {
        return this.$store.getters.gettersIsPlay;
      }
    },
    isActiveChannel() {
      if (this.$store.getters['player/getMyChannelInfo']) {
        return this.$store.getters['player/getMyChannelInfo'].channelId === this.$store.getters.gettersChannelId;
      }
      return false;
    },
    activeTab() {
      return this.$store.getters['player/getActiveTab'];
    },
    designatedRecommendedChannelsList() {
      const newDesignatedList = [];
      const cloneDesignatedRecommendedChannelsList = cloneDeep(
        this.$store.getters['player/getDesignatedRecommendedChannels']
      );
      const result = this.$store.getters['player/getBookmarkChannels'];
      cloneDesignatedRecommendedChannelsList.forEach(item => {
        const find = result.find(findItem => findItem.recomId === item.recomId);
        if (!find) {
          newDesignatedList.push(item);
        }
      });
      return newDesignatedList;
    },
    isPlayingState() {
      return this.$store.state.isPlay;
    },
    mySoundList() {
      return this.$store.getters['player/getMySoundList'];
    }
  },
  destroyed() {
    this.storeHlsWatch();
    clearInterval(this.channelInfoInterval);
    clearInterval(this.infoInterval);
    this.$store.commit('player/setAlertStatus', false);
    this.$store.commit('player/setIsLoading', false);
    this.$store.commit('player/setIsAlertModal', false);
    this.$store.commit('player/setAlertText', '');
    this.$store.commit('player/setIsOpenCurationSetting', false);
    window.removeEventListener('resize', rainAnimation.resize.bind(rainAnimation));
    this.$store.commit('setCarCurationModal', false);
    clearTimeout(this.timeOutId);
    clearInterval(this.similarInterval);
    clearInterval(this.showroomInterval);
  },
  watch: {
    async getUserType(val, oldVal) {
      if ((oldVal === USER_TYPE.SUBSCRIBE || oldVal === USER_TYPE.FREE_HAS_SPACE) && val === USER_TYPE.END_FREE) {
        this.$VideoPlayer.onPause();
        const { spaceImg, spaceNm } = this.$store.getters['player/getSpaceInfo'];
        setBackGroundImg(spaceImg);
        this.$store.commit('setSpaceNm', spaceNm);
      }
    },
    isMobileRecommend(val) {
      if (val !== '') {
        document.querySelector('html').style.overflowY = 'hidden';
      } else {
        document.querySelector('html').style.overflowY = '';
      }
    },
    isPlayingState(newVal) {
      // A1
      if (newVal && this.isFreeCouponUser) {
        // 스트리밍 음악 플레이중 && 무료 쿠폰 유저
        console.log('this.isAddedJobInitFreePlayer', this.$store.state.player.isAddedJobInitFreePlayer);
        if (!this.$store.state.player.isAddedJobInitFreePlayer) {
          // 크론잡 최초 1회 실행!
          this.$store.state.player.isAddedJobInitFreePlayer = true;
          this.addJobFreePlayer();

          if (this.$store.state.isPlay || !this.$store.state.isBrandMusicPlay) this.onFreePlayer();
          // 크론잡 최초1회  설정
        }
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/player/playerview.css"></style>
<style src="@/assets/css/player/playercommon.css"></style>
<style src="@/assets/css/player/mysound.css"></style>
